.page {
  background-color: whitesmoke;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  padding-top: 4rem;
  min-height: 100vh;
}

.postInteractionsInfo {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  color: gray;
}

.donationImagesContainer {
  position: relative;
  width: 4rem; /* To accommodate both 4rem images with 2rem overlap */
  height: 4rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.eventImagesContainer {
  position: relative;
  width: 5.25rem;
  height: 4rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.donorImage {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  object-fit: cover;
  z-index: 2;
  /* border: 0.1rem solid white; */
}

.donationStartupImage {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 1.25rem;
  /* border: 0.1rem solid white; */
  margin-left: -1rem;
  width: 2.625rem;
  height: 2.625rem;
  object-fit: cover;
  z-index: 1;
}

.communityImage,
.eventImage {
  border-radius: 0.5rem !important;
}

.eventImage {
  width: 4rem;
}

.eventInviteImageContainer {
  position: relative;
  display: flex;
  align-self: flex-start;
  width: 6rem;
  height: 4rem;
  margin-right: 0.5rem;
}

.eventInviteImageContainer img {
  width: 6rem;
}
.skillContainer {
  margin-top: 1rem;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: rgba(255, 69, 0, 0.1);
  border: solid 0.05rem transparent;
  color: orangered;
  font-weight: 900;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
}

.skillContainer svg {
  margin-right: 0.25rem;
  display: flex;
}

.interactions {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.dot {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.startupName {
  font-size: 1rem;
  color: black;
  font-weight: 900;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.startupName:hover {
  text-decoration: underline;
}

.content {
  display: flex;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  gap: 2rem;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
}

.startupImage {
  width: 4rem;
  height: 4rem;
  overflow: hidden;
  margin-right: 0.5rem;
  object-fit: cover;
  display: flex;
  cursor: pointer;
  align-self: flex-start;
  flex-shrink: 0;
}

.profileImage {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.5rem;
  object-fit: cover;
  display: flex;
  cursor: pointer;
  align-self: flex-start;
  flex-shrink: 0;
}

.profileImageContainer {
  position: relative;
  display: flex;
  align-self: flex-start;
}

.newNotificationIndicator {
  position: absolute;
  bottom: 0;
  left: -1rem;
  width: 0.25rem;
  height: 100%;
  background-color: orangered;
  /* border: 0.125rem solid orangered; */
}

.startupNotificationIndicator {
  position: absolute;
  bottom: 0;
  left: -1rem;
  width: 0.25rem;
  height: 100%;
  background-color: orangered;
  /* border: 0.125rem solid orangered; */
}

.notifications {
  display: flex;
  flex-direction: column;
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.homeButton {
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  margin-top: 1rem;
  transition: 0.2s;
  border-radius: 10rem;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: white;
  border: 0.05rem solid orangered;
  color: orangered;
  font-weight: 900;
  cursor: pointer;
  width: 6rem;
  display: flex;
  align-items: center;
}

.homeButton:hover {
  background-color: orangered;
  color: white;
}

.homeButton svg {
  margin-right: 0.25rem;
}

.noNotifications {
  padding: 1rem;
  color: gray;
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 1rem;
}

.notification {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 0.05rem solid lightgray;
}

.notification:last-child {
  border-bottom: none;
}

.notificationContent {
  display: flex;
  width: 100%;
  align-items: center; /* Add this line */
  justify-content: space-between; /* Add this line */
}

.notificationText {
  flex: 1;
  min-width: 0;
}

.cardTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 900;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: black; /* Default color */
}

.userName:hover {
  text-decoration: underline;
}

.rightCards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 20rem;
  position: sticky;
  top: 6rem; /* Account for the navbar height + some padding */
  height: fit-content;
  align-self: flex-start;
}

.rightCards,
.centreCards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 20rem;
}

.button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  /* margin-top: 1rem; */
  transition: 0.2s;
  border-radius: 10rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: orangered;
  border: 0.05rem solid orangered;
  color: white;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button svg,
.ignoreButton svg {
  margin-right: 0.25rem;
}

.notificationMessage {
  display: inline; /* Change from flex to inline */
  overflow: visible;
}

.userName {
  font-weight: 900;
  cursor: pointer;
  word-break: break-word;
  overflow-wrap: break-word;
  display: inline; /* Make it flow with text */
}

.userName:hover {
  text-decoration: underline;
}

.messageText {
  display: inline; /* Make it flow with text */
}

.notification p {
  font-size: 1rem;
  margin: 0;
  width: 100%;
  word-wrap: break-word; /* Ensure long words wrap */
}

.ignoreButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 10rem;
  transition: 0.2s;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
  border: 0.05rem solid whitesmoke;
  color: gray;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ignoreButton:hover {
  filter: brightness(95%);
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button:hover:enabled {
  filter: brightness(95%);
}

.mediaPreview {
  width: 4rem;
  height: 4rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.5rem;
  height: 1.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playButton::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 0.3rem solid transparent;
  border-bottom: 0.3rem solid transparent;
  border-left: 0.5rem solid white;
  margin-left: 0.125rem; /* Slight adjustment to center the triangle */
}

.mediaPreview img,
.mediaPreview video {
  width: 100%;

  height: 100%;
  object-fit: cover;
}

.mediaPreview video {
  position: absolute;
  top: 0;
  left: 0;
}

.postPreview .commentPreview {
  flex: 1;
  /* border-bottom: 0.05rem solid lightgray; */
  padding-left: 0.5rem;
  /* padding-top: 0.5rem;
  padding-bottom: 0.5rem; */
  cursor: pointer;
  padding-right: 0.5rem;
  color: black;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3.1rem;
  margin: 0;
  font-size: 1rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
}

.centreCards {
  width: 36rem;
}

.replyPreview {
  flex: 1;
  border-bottom: 0.05rem solid lightgray;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  padding-right: 0.5rem;
  color: black;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3.1rem;
  margin: 0;
  font-size: 1rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
}

.parentCommentPreview {
  flex: 1;
  cursor: pointer;
  padding-left: 0.5rem;

  /* background-color: whitesmoke; */
  padding-right: 0.5rem;
  color: black;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3.1rem;

  margin: 0;
  font-size: 1rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
  /* border-bottom: 0.05rem solid lightgray; */
}

.miscButton {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  color: gray;
  font-family: "Roboto", sans-serif;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 900;
}

.miscButton:hover {
  text-decoration: underline;
}

.miscButton svg {
  margin-left: 0.25rem;
}

.filePreview {
  width: 4rem;
  height: 4rem;
  background-color: rgba(255, 69, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.filePreview svg {
  width: 2rem;
  height: 2rem;
  color: orangered;
}

.postPreview {
  cursor: pointer;
  margin-top: 1rem;
  border: 0.05rem solid lightgray;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: 0.2s;
}

.postPreview:hover {
  background-color: whitesmoke;
}

/* .textOnlyPost {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
} */

.commentReply {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.05rem solid lightgray;
}

.commentPost {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.05rem solid lightgray;
}

.showAllNotifications {
  display: flex;
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: -1rem;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0 0 0.75rem 0.75rem;
  border-top: 0.05rem solid lightgray;
  cursor: pointer;
  font-weight: 900;
  color: gray;
  transition: 0.2s;
}

.showAllNotifications:hover {
  filter: brightness(95%);
  background-color: whitesmoke;
}

.showAllNotifications svg {
  margin-left: 0.5rem;
}

.postContent {
  display: flex;
  align-items: center;
}

.postContent img {
  object-fit: cover;
  width: 4rem;
  height: 4rem;
  border-right: 0.05rem solid lightgray;
}

.post {
  /* border-radius: 0.5rem; */
  overflow: hidden;
}

.postContent {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  max-height: 4rem;
}

.bold {
  font-size: 1rem;
  font-weight: 900;
  color: gray;
}

.text {
  margin-top: 0.25rem;
  font-size: 1rem;
}

.postContent .textPreview {
  flex: 1;
  /* border-bottom: 0.05rem solid lightgray; */
  padding-left: 0.5rem;
  /* padding-top: 0.5rem;
  padding-bottom: 0.5rem; */
  cursor: pointer;
  padding-right: 0.5rem;
  color: black;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3.1rem;
  margin: 0;
  font-size: 1rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
}

.timestamp {
  color: gray;
  margin-top: 0.25rem;
  font-size: 0.875rem;
}

.userInput::placeholder {
  color: grey;
}

.emoji {
  margin-left: 0.25rem;
  /* margin-right: 0.25rem; */
}

.notificationTimestamp {
  font-size: 0.875rem;
  padding-top: 0.25rem;
  color: gray;
  margin: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.welcomeTitle {
  font-weight: 900;
  font-size: 1rem;
}

.welcomeMessage {
  margin-top: 1rem;
  margin-bottom: 1rem;

  color: gray;
}

.cardAbout {
  display: flex;
  background-color: rgba(255, 69, 0, 0.1);
  flex-wrap: wrap;
  border-radius: 0.5rem;

  /* gap: 0.5rem; */
}

.matchTally {
  margin-top: 0.75rem;
  display: inline-flex;
  align-items: center;
  color: orangered;
  font-weight: 900;
  padding: 0.5rem;
  /* background-color: rgba(255, 69, 0, 0.1); */
  border: solid 0.05rem transparent;
  height: 100%;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  width: fit-content;
  margin: 0;
}

.matchTally svg {
  margin-right: 0.25rem;
  flex-shrink: 0;
  display: flex;
}

.loadingIndicator {
  display: flex;
  justify-content: center;
}

.loadingIcon {
  color: gray;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

@media (max-width: 990px) {
  .content {
    display: block;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    gap: 0rem;
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
  }

  .rightCards,
  .centreCards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  .leftCards {
    padding-top: 1rem;
    /* display: none; */
  }

  .centreCards {
    width: 100%;
  }
}

@media (max-width: 648px) {
  .content {
    display: block;
    padding-top: 1rem;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 4.8rem;
    /* margin-bottom: ; */
    gap: 0rem;
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
  }

  .rightCards,
  .centreCards {
    display: flex;
    flex-direction: column;
    /* justify-self: center; */
    gap: 1rem;
    width: 100%;
  }

  .centreCards {
    width: 100%;
  }

  .notifications {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  /* .notificationMessage {
    display: block;
  } */

  .textPreviewWrapper {
    flex: 1;
    min-width: 0;
  }

  .notification:first-child {
    border-top: none;
  }

  .notification:first-child:hover {
    border-radius: 0;
  }

  .notification:last-child {
    border-radius: 0;
  }

  .noNotifications {
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray; */
    border-radius: 0rem;
  }
}
