.activityButtons {
  display: flex;
  gap: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.activityButton svg {
  margin-right: 0.25rem;
}

.activityButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 0.05rem solid whitesmoke;
  background-color: whitesmoke;
  color: gray;
  cursor: pointer;
  border-radius: 10rem;
  transition: 0.2s;
  font-weight: 900;
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
}

.activityButton:hover {
  background-color: whitesmoke;
  filter: brightness(95%);

  border-color: whitesmoke;
}

.activeButton {
  background-color: orangered;
  color: white;
  font-weight: 900;
  border-color: orangered;
}

.activeButton:hover {
  border-color: orangered;
  background-color: orangered;
  filter: brightness(95%);
}
