.bodyNoScroll {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: flex-start; /* Align items to the start to keep them at the top */
  justify-content: center;
  padding-top: 2rem; /* Add padding to the top */
  z-index: 1000; /* Ensure it appears above other content */
  overflow-y: auto;
}

.closeIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.375rem;
  cursor: pointer;

  transition: 0.2s;
  /* margin-right: -0.625rem; */
  /* margin-bottom: -0.25rem; */
}

.closeIcon:hover {
  background-color: whitesmoke;
}

.interactions::-webkit-scrollbar {
  display: none;
}

.cardHeader {
  background-color: white;
  padding: 1rem;
  border-radius: 1rem 1rem 0 0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.cardFooter {
  background-color: white;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}

.interactions {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
}

.label {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.button,
.textArea {
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
}

.textArea {
  padding: 1rem;
  margin-top: -1rem;

  flex-grow: 1;
  background-color: white;
  color: black;
  border: none;
  resize: none;
  box-sizing: border-box;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
}

.textArea::-webkit-scrollbar {
  display: none;
}

.button {
  padding: 0.5rem;
  border-radius: 10rem;
  background-color: orangered;
  border: 0.05rem solid orangered;
  color: white;
  font-weight: 900;
  cursor: pointer;
  transition: 0.2s;
  width: 100%;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button:hover:enabled {
  filter: brightness(95%);
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 900;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIndicator {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
}

.loadingIcon {
  animation: spin 1s linear infinite;
  font-size: 1rem; /* Adjust size as needed */
  color: gray; /* Adjust color as needed */
}

.saveIcon {
  animation: spin 1s linear infinite;
  font-size: 1rem; /* Adjust size as needed */
  color: white; /* Adjust color as needed */
}

.loadingIcon,
.saveIcon {
  padding: 0.1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.charCount {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: gray;
  display: flex;
  justify-content: flex-end;
}

.textArea {
  padding: 1rem;
  padding-bottom: 5rem;

  flex-grow: 1;
  background-color: white;
  color: black;
  border: none;
  border-radius: 0;
  resize: none;
  box-sizing: border-box;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
}

.modalContent {
  position: fixed;
  top: 2rem;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
  max-height: calc(100vh - 0rem);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 36rem;
  background-color: white;
  border-radius: 1rem;
  z-index: 1000;
}

.interactions {
  flex-grow: 1;
  overflow-y: auto;
}

.cardFooter {
  width: 36rem;

  position: fixed; /* Change to relative positioning */
  bottom: 0; /* Remove bottom positioning */
}

.cardFooter {
  padding-bottom: 1rem;
}

.interactions {
  padding-bottom: 1rem;
}

@media (max-width: 991px) {
  .textArea {
    padding: 1rem;
    padding-bottom: 5rem;

    flex-grow: 1;
    background-color: white;
    color: black;
    border: none;
    resize: none;
    box-sizing: border-box;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-y;
  }

  .modalContent {
    position: fixed; /* Change to fixed positioning */
    top: 0rem;
    bottom: 0; /* Stick to the bottom */
    left: 0;
    right: 0;
    transform: none; /* Remove transform */
    max-height: 100vh; /* Limit height to 90% of viewport height */
    height: auto; /* Allow height to adjust based on content */
    width: 100%;
    border-radius: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
  }

  .interactions {
    flex-grow: 1;
    overflow-y: auto;
  }

  .cardFooter {
    width: 100%;
    position: fixed; /* Change to relative positioning */
    bottom: 0; /* Remove bottom positioning */
  }

  .cardFooter {
    padding-bottom: 2rem;
  }

  .interactions {
    padding-bottom: 1rem;
  }
}
