/* Global styles */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

html {
  background-color: whitesmoke;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: whitesmoke;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Font styles */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  font-size: 1.4063rem;
}

h1 {
  font-size: 3.375rem;
}

h2 {
  font-size: 2.8125rem;
}

label {
  color: gray;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
div {
  -webkit-user-select: text;
  user-select: text;
}
