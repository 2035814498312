.page {
  background-color: whitesmoke;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  padding-top: 4rem;
  min-height: 100vh;
}

.reactionUsername {
  font-weight: 900;
  margin-right: 0.25rem;
}

.reactionUsername:hover {
  text-decoration: underline;
}

.miscButton {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  color: gray;
  font-family: "Roboto", sans-serif;
  outline: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 900;
}

.miscButton:hover {
  text-decoration: underline;
}

.miscButton svg {
  margin-left: 0.25rem;
}

.profileImageContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profileImageContainer img {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
}

.editCommentSection {
  padding: 0;
}

.editCommentButtons {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.cancelEditButton {
  font-size: 0.875rem;
  color: gray;
  cursor: pointer;
}

.cancelEditButton:hover {
  text-decoration: underline;
}

.saveChangesButton {
  font-size: 0.875rem;
  color: orangered;
  cursor: pointer;
  font-weight: 900;
}

.saveChangesButton:hover {
  text-decoration: underline;
}

.saveChangesButton.disabledButton {
  opacity: 0.5;
  cursor: not-allowed;
}

.saveChangesButton.disabledButton:hover {
  text-decoration: none;
  cursor: not-allowed;
}

.addReplyButton.disabled,
.addCommentButton.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.addReplyButton.disabled:hover,
.addCommentButton.disabled:hover {
  text-decoration: none;
  cursor: not-allowed;
}

.editReplyInput {
  font-size: 1rem;
  width: 100%;
  font-family: "Roboto", sans-serif;
  padding: 0.5rem;
  border: 0.05rem solid whitesmoke;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  background-color: whitesmoke;
  resize: none;
  overflow-y: auto;
  outline: none;
}

.noScroll {
  position: fixed;
  width: 100%;
  overflow: hidden;
}

.imageModalContent {
  width: 90vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  width: auto;
  height: auto;
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  padding-left: 0.6rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.reactionStack {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-left: 0.25rem;
}

.postButton.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.reactionEmoji {
  width: 0.875rem;
  height: 0.875rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: var(--emoji-font-size, 0.875rem);
  margin-top: var(--emoji-margin-top, 0.125rem);
  margin-left: var(--emoji-margin-left, -0.25rem);
  cursor: pointer;
  line-height: 1;
}

.reactionCount {
  margin-left: var(--count-margin-left, 0.25rem);
  font-size: 0.875rem;
  color: gray;
  cursor: pointer;
}

.reactionCount:hover {
  color: orangered;
  text-decoration: underline;
}

.postOptions {
  position: relative;
  display: flex;
  align-items: center;
}

.emojiButton {
  font-size: 1rem;
  line-height: 1;
  padding-left: 0.325rem;
  background: none;
  border: none;
  cursor: pointer;
}

.optionsIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  transition: 0.2s;
  cursor: pointer;
}

.optionsIcon:hover,
.optionsIconActive {
  background-color: whitesmoke;
}

.reactionStack {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-left: 0.25rem;
}

.reactionsSummary {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.reactionsAndEmojis {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.reactionCount {
  position: relative;
}

.reactionsSummary:hover .reactionCount {
  color: orangered;
  text-decoration: underline;
}

.commentCount,
.repostCount {
  color: gray;
  font-size: 0.875rem;
  cursor: pointer;
}

.commentCount:hover,
.repostCount:hover {
  color: orangered;
  text-decoration: underline;
}

.optionsDropdown {
  z-index: 10;
  position: absolute;
  top: 3rem;
  box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);
  right: 0;
  background-color: whitesmoke;
  border-radius: 1rem 0 1rem 1rem;
  display: flex;
  flex-direction: column;
}

.optionsDropdown button {
  display: flex;
  align-items: center;
  width: 100%;
  font-family: "Roboto";
  padding: 0.5rem;
  color: gray;
  font-size: 1rem;
  border: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s;
}

.optionsDropdown svg,
.reportCommentButton svg,
.singleReportButton svg,
.reportUserCommentButton svg {
  margin-right: 0.25rem;
}

.optionsDropdown button:hover,
.reportCommentButton:hover,
.singleReportButton:hover,
.deleteCommentButton:hover,
.editCommentButton:hover,
.reportUserCommentButton:hover {
  background-color: whitesmoke;
  filter: brightness(95%);
}

.singleReportButton {
  display: flex;
  align-items: center;
  width: 100%;
  font-family: "Roboto";
  padding: 0.5rem;
  border-radius: 1rem 0 1rem 1rem;
  color: gray;
  font-size: 1rem;
  border: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s;
  z-index: 1;
}

.deleteCommentButton {
  display: flex;
  align-items: center;
  width: 100%;
  font-family: "Roboto";
  padding: 0.5rem;
  border-radius: 0 0 1rem 1rem;
  color: gray;
  font-size: 1rem;
  border: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s;
  z-index: 1;
}

.editCommentButton {
  display: flex;
  align-items: center;
  width: 100%;
  font-family: "Roboto";
  padding: 0.5rem;
  border-radius: 1rem 0 0 0;
  color: gray;
  font-size: 1rem;
  border: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s;
}

.reportCommentButton {
  display: flex;
  align-items: center;
  width: 100%;
  font-family: "Roboto";
  padding: 0.5rem;
  border-radius: 1rem 0 0 0;
  color: gray;
  font-size: 1rem;
  border: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s;
}

.reportUserCommentButton {
  display: flex;
  align-items: center;
  width: 100%;
  font-family: "Roboto";
  padding: 0.5rem;
  border-radius: 1rem 0 0 0;
  color: gray;
  font-size: 1rem;
  border: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s;
}

.singleReportComment {
  display: flex;
  align-items: center;
  width: 100%;
  font-family: "Roboto";
  padding: 0.5rem;
  border-radius: 1rem 0 1rem 1rem;
  color: gray;
  font-size: 1rem;
  border: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s;
}

.hidePost {
  border-radius: 1rem 0 0 0;
}

.reportPost {
  border-radius: 1rem 0 1rem 1rem;
}

.deletePost {
  border-radius: 0 0 1rem 1rem;
}

.editPost {
  border-radius: 1rem 0 0 0;
}

/* .optionsDropdown button:first-child {
  border-radius: 1rem 0 0 0;
} */

.postInteractionsInfo {
  color: gray;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
}

.hasMediaContent .postInteractionsInfo {
  padding-top: 1rem;
}

/* Support for specific media types */
.hasImage .postInteractionsInfo,
.hasMultipleImages .postInteractionsInfo,
.hasVideo .postInteractionsInfo {
  padding-top: 1rem;
}

.postInteractionsInfo:hover {
  text-decoration: underline;
  color: orangered;
}

.postInteractionsInfo svg {
  margin-left: 0.25rem;
}

.leftInteractions,
.rightInteractions {
  display: flex;
  align-items: center;
}

.postInteractions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 1rem;
  border-top: 0.05rem solid lightgray;
}

.postInteractions img {
  width: 2rem !important;
  height: 2rem !important;
}

video {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.postText {
  display: inline;
}

.postTextContainerNoInteractions {
  margin-bottom: 1rem;
}

.postTextContainer {
  display: block;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.showMoreButton {
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  padding: 0;
  margin-left: 0.25rem;
  font-size: 1rem;
  display: inline;
  vertical-align: baseline;
}

.showMoreButton:hover {
  text-decoration: underline;
  color: orangered;
}

.postText a {
  color: orangered;
  font-weight: 900;
  text-decoration: none;
}

.postText a:hover {
  text-decoration: underline;
}

.content {
  display: flex;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  gap: 2rem;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
}

.cardAbout {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.skillContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: rgba(255, 69, 0, 0.1);
  border: solid 0.05rem transparent;
  color: orangered;
  font-weight: 900;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
}

.skillContainer svg,
.emptySkillContainer svg,
.eventPassedContainer svg {
  margin-right: 0.25rem;
  display: flex;
}

.interactionButton {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
  border: 0.05rem solid whitesmoke;
  color: gray;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.interactionButton:hover {
  background-color: whitesmoke;
  filter: brightness(95%);
}

.interactionButton svg,
.activeInteraction svg {
  margin-right: 0.25rem;
  display: flex;
}

.activeInteraction {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: orangered;
  border: 0.05rem solid orangered;
  color: white;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.activeInteraction:hover {
  background-color: orangered;
  filter: brightness(95%);
}

.interactionToggle {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
  border: 0.05rem solid whitesmoke;
  color: gray;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.interactionToggle:hover,
.profileImageContainer:hover {
  background-color: whitesmoke;
  filter: brightness(95%);
}

.topCard .interactionToggle img {
  width: 2rem !important;
  height: 2rem !important;
}

.interactionToggle img {
  width: 1rem !important;
  height: 1rem !important;
  border-radius: 50%;
}

/* Override border-radius for startup images */
.interactionToggle img[data-is-startup="true"] {
  border-radius: 0;
}

.rightCards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 20rem;
  position: sticky;
  top: 6rem; /* Account for the navbar height + some padding */
  height: fit-content;
  align-self: flex-start;
}

.rightCards,
.centreCards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 20rem;
}

.centreCards {
  width: 36rem;
}

.postContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userInfo {
  display: flex;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
}

.userDetails {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  margin-left: 0.5rem;
  margin-right: 1rem;
}

.interactions {
  margin-top: -0.25rem;
  display: flex;
  cursor: pointer;
}

.userNameContainer {
  display: flex;
  align-items: baseline;
  width: 100%;
  overflow: hidden;
}

.userName {
  font-weight: 900;
  font-size: 1rem;
  margin: 0;
  padding-right: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  cursor: pointer;
}

.userNameDetails {
  display: flex;
  align-items: center;
}

.userName:hover {
  text-decoration: underline;
}

.bio {
  margin: 0;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.time {
  font-size: 0.875rem;
  color: gray;
  margin: 0;
}

.postHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
}

.postCard {
  position: relative;
  background-color: white;
  border-radius: 1rem;
  overflow: hidden;
}

.postCard:last-child {
  margin-bottom: 0;
}

.emojiPickerWrapper {
  position: relative;
  bottom: 0rem;
  left: 0rem;
  margin-top: 1rem;

  margin-bottom: 1rem;
  z-index: 10;
}

.emojiPostPickerWrapper {
  position: relative;
  bottom: 0rem;
  left: 0rem;

  margin-bottom: 1rem;

  margin-left: 1rem;
  margin-right: 1rem;
  z-index: 10;
}

.topCard {
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 1rem;
  overflow: hidden;
  padding: 1rem;
}

.topCard img {
  width: 3rem !important;
  height: 3rem !important;
}

.profileImageContainer {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
  border: 0.05rem solid whitesmoke;
  color: gray;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.topCard svg {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  padding: 0;
  color: gray;
}

.postContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.videoContainer {
  position: relative;
  width: 100%;
  z-index: 1; /* Add this */
}

.videoControls {
  position: absolute;
  bottom: 1rem;
  right: 1rem;

  display: flex;
  justify-content: space-between;
  z-index: 2; /* Add this */
}

.imageCarousel {
  position: relative;
  transition: 0.2s;
  width: 100%;
  overflow: hidden;
}

.carouselControls {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 100rem;
  padding: 0.25rem;
}

.carouselButton {
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  padding: 0 0.5rem;
}

.carouselButton:hover {
  opacity: 0.5;
}

.postContentImage {
  width: 100%;
  height: auto;
  display: block;
  transition: 0.2s;
}

.postCard {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
}

.postContentImage {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.postContentWrapper {
  contain: content;
  content-visibility: auto;
}

.imageContainer {
  position: relative;
  width: 100%;

  height: 100%;
}

.imageContainer .loadingIndicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.imageCounter {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-weight: 600;
  font-size: 0.875rem;
  z-index: 2;
}

.fullscreenControl {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;
}

.commentsSection {
  display: flex;
  flex-direction: column;
  /* padding-bottom: 1rem; */
}

/* Remove negative margin ONLY for direct replies to last comment */
.commentsList > .commentItem:last-child > .replyCommentInputSection {
  margin-bottom: 0;
}

/* Ensure nested replies maintain negative margin */

.commentInputSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.commentInputWrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding-left: 1rem;
  padding-right: 1rem;
}

.commentUserLinkContainer,
.replyUserLinkContainer {
  overflow: hidden;
  margin-top: 0.25rem;
}

.commentUserLink,
.replyUserLink {
  display: flex;
  align-items: center;
  max-width: 100%;
  color: orangered;
  text-decoration: none;
  font-weight: 900;
  font-size: 0.875rem;
}

.commentUserLink:hover,
.replyUserLink:hover {
  text-decoration: underline;
}

.commentUserLinkText,
.replyUserLinkText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* flex: 1; */
  min-width: 0;
}

.commentUserLinkIcon,
.replyUserLinkIcon {
  flex-shrink: 0;
  margin-left: 0.25rem;
}

.userLinkContainer {
  /* margin-top: 0.25rem; */
  overflow: hidden;
}

.userLink {
  display: flex;
  align-items: center;
  max-width: 100%;
  color: orangered;
  text-decoration: none;
  font-weight: 900;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.userLinkText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* flex: 1; */
  min-width: 0;
}

.userLink:hover .userLinkText {
  text-decoration: underline;
}

.userLinkIcon {
  flex-shrink: 0;
  margin-left: 0.25rem;
}

.commentInput {
  font-size: 1rem;
  width: 100%;
  font-family: "Roboto", sans-serif;
  padding: 0.5rem;
  border: 0.05rem solid whitesmoke;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  background-color: whitesmoke;
  resize: none;
  overflow-y: auto;
  outline: none;
}

.commentInput:focus {
  outline: none; /* Ensure no outline appears on focus */
  border-color: whitesmoke; /* Keep the border color consistent when focused */
}

.commentInput::-webkit-scrollbar {
  display: none;
}

.commentActions {
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
  margin-left: 2.5rem;
  justify-content: flex-start;
}

.editedText {
  font-size: 0.875rem;
  color: gray;
  margin-left: auto; /* This will push the element to the right */
}

.replyActions {
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
  margin-left: 2.5rem;
}

.addCommentButton {
  margin-top: 0.5rem;
  display: flex;
  margin-right: 1rem;
  align-self: flex-end;
  font-size: 0.875rem;
  border-radius: 0.5rem;
  color: orangered;
  border: none;
  font-weight: 900;
  cursor: pointer;
}

.addCommentButton:hover {
  text-decoration: underline;
}

/* .commentsList {
  margin-bottom: 1rem;
} */

.commentDetails {
  display: flex;
  width: 100%;
}

.commentItem {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  padding: 0;
}

.commentItem:first-child {
  margin-top: 0rem;
}

.commentUserDetails,
.replyUserDetails {
  flex: 1;
  min-width: 0;
  overflow: hidden;
}

.commentUserImage {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  flex-shrink: 0;
  object-fit: cover;
  cursor: pointer;
}

.replyUserImage {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  flex-shrink: 0;
  object-fit: cover;
  cursor: pointer;
}

.commentContent,
.replyContent {
  margin-left: 2.5rem;
  margin-top: 0.5rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.commentUserInfo,
.replyUserInfo {
  display: flex;
  flex: 1;
  min-width: 0;
}

.repliesContainer {
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
}

.replyItem {
  margin-top: 1rem;
}

.replyCount {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 0.875rem;
  margin-left: 0.25rem;
  text-decoration: none;
}

.replyButton {
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  text-align: left;
}

.replyButton .replyText {
  font-size: 0.875rem;
}

.reactButton {
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  text-align: left;
}

.reactButton:hover {
  text-decoration: underline;
}

.commentReactions {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: gray;
  margin-left: 0.25rem;
}

.commentEmojiWrapper {
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--emoji-font-size, 0.875rem);
  border-radius: 50%;
}

.commentEmojiWrapper:not(:last-child) {
  margin-left: -0.5rem;
}

.commentEmojiWrapper span {
  line-height: 1;
}

.commentEmojis {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 0.25rem;
}

.replyButton:hover .replyText {
  text-decoration: underline;
}

.replyInputSection {
  margin-top: 0.5rem;
  display: flex;
  margin-left: 1rem;
  margin-right: 1rem;
  align-items: center;
}

.replyInputWrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

/* Add this new class for nested replies */

.replyCommentInputSection {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  padding-left: 2.5rem;
  margin-bottom: -1rem;
}

/* Clear all margins and set new ones for nested replies */
.repliesContainer .replyCommentInputSection {
  margin-left: 0 !important;
  margin-top: 1rem !important;
}

.replyToReplyCommentInputSection {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.replyInput {
  font-size: 1rem;

  width: 100%;
  font-family: "Roboto", sans-serif;
  padding: 0.5rem;
  border: 0.05rem solid whitesmoke;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  background-color: whitesmoke;
  resize: none;
  overflow-y: auto;
  outline: none;
}

.replyInput:focus {
  outline: none; /* Ensure no outline appears on focus */
  border-color: whitesmoke; /* Keep the border color consistent when focused */
}

.replyInput::-webkit-scrollbar {
  display: none;
}

.addReplyButton {
  margin-top: 0.5rem;
  display: flex;
  align-self: flex-end;
  font-size: 0.875rem;
  color: orangered;
  font-weight: 900;
  cursor: pointer;
}

.addReplyButton:hover {
  text-decoration: underline;
}

.commentUserBio,
.replyUserBio {
  font-size: 0.875rem;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  margin-top: 0.25rem;
}

.commentUserNameContainer,
.replyUserNameContainer {
  display: flex;
  align-items: baseline;
  width: 100%;
  overflow: hidden;
}

.nestedReplyInput {
  margin-left: 2rem;
}

.commentConnectionType,
.replyConnectionType {
  font-size: 0.875rem;
  color: gray;
  white-space: nowrap;
  margin-left: 0.25rem;
  font-weight: 400;
  flex-shrink: 0;
}

.commentText a,
.replyText a {
  color: orangered;
  font-weight: 900;
  text-decoration: none;
}

.commentText a:hover,
.replyText a:hover {
  text-decoration: underline;
}

.commentUserName,
.replyUserName {
  font-weight: 900;
  font-size: 1rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  cursor: pointer;
}

.commentUserName:hover,
.replyUserName:hover {
  text-decoration: underline;
}

.commentOptionsDropdown,
.replyOptionsDropdown {
  position: absolute;
  top: 3rem;
  right: 0;
  box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);
  background-color: whitesmoke;
  border-radius: 1rem 0 1rem 1rem;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.commentOptions,
.replyOptions {
  position: relative;
  margin-left: 1rem;
  flex-shrink: 0;
  padding: 0;
  display: flex;
}

.viewMoreRepliesButton,
.loadMoreCommentsButton {
  font-size: 0.875rem;
  color: orangered;
  font-weight: 900;
  cursor: pointer;
  margin-top: 1rem;
}

.loadMoreCommentsButton {
  margin: 1rem;
}

.viewMoreRepliesButton:hover,
.loadMoreCommentsButton:hover {
  text-decoration: underline;
}

.commentOptionsIcon,
.replyOptionsIcon {
  background-color: transparent;
  border-radius: 50%;
  padding: 0.5rem;
  transition: 0.2s;
  cursor: pointer;
}

.commentOptionsIcon:hover,
.replyOptionsIcon:hover,
.commentOptionsIconActive,
.replyOptionsIconActive {
  background-color: whitesmoke;
}

.commentHeader,
.replyHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.deleteCommentButton svg,
.deleteReplyButton svg,
.editCommentButton svg {
  margin-right: 0.25rem;
}

.commentTimestamp,
.replyTimestamp {
  font-size: 0.875rem;
  color: gray;
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  margin-bottom: 0;
}

.commentEditText {
  font-size: 0.875rem;
  color: gray;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
}

.timeAndEditDetails {
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
}

.videoControlsLeft {
  position: absolute;
  bottom: 0.5rem;
  right: 3rem;
  display: flex;
  z-index: 2;
}

.videoTimeCounter {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 2;
}

.videoTimeCounterInner {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

.videoControlsRight {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  display: flex;
  z-index: 2;
}

.editDot {
  font-size: 0.875rem;
  color: gray;
  margin-left: 0.25rem;
  font-weight: 400;
}

.editedPostText {
  font-size: 0.875rem;
  color: gray;
  margin-left: 0.25rem;
}

.imageCounterInner {
  color: white;
  border-radius: 100rem;
  font-size: 0.875rem;
}

.navigationButtons {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  display: flex;
  gap: 0.5rem;
  z-index: 2;
}

.navButton {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s;
}

.navButton:hover {
  background-color: rgba(0, 0, 0, 1);
}

.navButton svg {
  margin: 0 !important;
  padding: 0 !important;
}

.commentEditDot {
  font-size: 0.875rem;
  color: gray;
  margin-top: 0.25rem;
  margin-left: 0.25rem;
}

.commentTimeAndEditDetails {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.replyTextContainer {
  margin-left: -0.5rem;
}

.commentTextContainer,
.replyTextContainer {
  background-color: whitesmoke;
  padding: 0.5rem;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  position: relative;
  margin: 0;
}

.commentText,
.replyText {
  font-size: 1rem;
  margin: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: none;
  white-space: pre-wrap;
  display: inline;
}

.fullscreenControl:hover {
  background-color: rgba(0, 0, 0, 1);
}

.fullscreenControl svg {
  width: 1rem;
  height: 1rem;
}

.volumeControl {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;
  z-index: 10;

  position: relative; /* Add this */
  padding: 0; /* Add this */
}

.volumeControl:hover {
  background-color: rgba(0, 0, 0, 1);
}

.volumeControl svg {
  width: 1rem;
  height: 1rem;
}

.postContentVideo {
  object-fit: contain;
}

.postContentImage,
.postContentVideo {
  max-width: 100%;
  height: auto;
  display: block;
}

.reactionHeader {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  border-bottom: 0.05rem solid lightgray;
}

.suggestedText {
  font-size: 0.875rem;
  /* margin-left: -0.25rem; */
}

.pdfLink {
  padding: 0.5rem;
  background-color: orangered;
  border-radius: 0.25rem;
  color: white;
  font-weight: 900;
}

.pdfLink a {
  color: white;
  text-decoration: none;
}

.pdfLink a:hover {
  text-decoration: underline;
}

.postIcon {
  cursor: pointer;
}

.postImage {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  flex-shrink: 0;
  cursor: pointer;
}

.reactionImage {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-right: 0.5rem;
  align-items: center;
  object-fit: cover;
  flex-shrink: 0;
  cursor: pointer;
}

.defaultProfileImage {
  width: 8rem;
  height: 8rem;
  border-radius: 50%; /* Makes the container circular */
  background-color: orangered; /* Background color for the circle */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px; /* Adjust font size as needed */
  color: white; /* Color of the initial */
}

.card {
  background-color: white;
  border: 0.05rem solid lightgray;
  border-radius: 0.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  /* position: absolute;
  top: 6rem;
  bottom: 2rem; */
}

.cardTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.experiencesCard,
.educationCard {
  background-color: white;
  border: 0.05rem solid lightgray;
  border-radius: 0.5rem;
  padding-bottom: 1rem;
}

.experiencesCardTitle,
.educationCardTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 1rem;
  padding: 1rem;
}

.experiencesCardTitle div,
.educationCardTitle div {
  display: flex;
}

.addIcon {
  margin-right: 0.5rem;
  cursor: pointer;
}

.pencilIcon {
  cursor: pointer;
}

.profileTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 2rem;
}

.profileTitle span {
  display: flex;
  align-items: center;
}

.imageCloseIcon:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.noNotifications {
  padding: 1rem;
  color: gray;
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 1rem;
}

.bold {
  font-size: 1rem;
  font-weight: 900;
  color: gray;
}

.text {
  margin-top: 0.25rem;
  font-size: 1rem;
}

.homeButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  margin-top: 1rem;
  transition: 0.2s;
  border-radius: 10rem;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: white;
  border: 0.05rem solid orangered;
  color: orangered;
  font-weight: 900;
  cursor: pointer;
  width: 7rem;
  display: flex;
  align-items: center;
}

.homeButton:hover {
  background-color: orangered;
  color: white;
}

.homeButton svg {
  margin-right: 0.25rem;
}

.connectionType {
  font-size: 0.875rem;
  color: gray;
  white-space: nowrap;
  flex-shrink: 0;
}

.pdfPreview {
  display: block;
  text-decoration: none;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  background-color: white;
}

.pdfBox {
  width: 100%;
  /* aspect-ratio: 1; */
  border-radius: 0.5rem;
  background-color: rgba(255, 69, 0, 0.1);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fileContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.fileAbout {
  display: flex;
  flex-direction: column;
}

.fileName {
  color: orangered;
  font-size: 1.5rem;
  font-weight: 900;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  border-bottom: 0.05rem solid orangered;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.open {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: orangered;
  text-decoration: none;
  font-weight: 900;
  margin-top: auto;
}

.open svg {
  margin-left: 0.25rem;
}

.open:hover {
  text-decoration: underline;
}

.matchTally {
  display: flex;
  align-items: center;
  color: orangered;
  font-weight: 900;
  padding: 0.5rem;
  background-color: rgba(255, 69, 0, 0.25);
  height: 100%;
  font-size: 1rem;
  border-radius: 0.25rem;
  box-sizing: border-box;
  outline: none;
}

.matchTally svg {
  margin-right: 0.25rem;
}

.noResults {
  color: gray;
}

.spacing {
  margin-right: 0.375rem;
}

.location {
  margin-bottom: 0.5rem;
  color: gray;
  display: flex;
  align-items: center;
}

.svgContainer {
  cursor: pointer;
}

.numbers:hover {
  cursor: pointer;
  text-decoration: underline;
}

.modalCard {
  background-color: white;
  width: 80%;
  max-width: 600px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);
}

.dotInfo {
  font-size: 0.75rem;
  color: gray;
  font-weight: 400;
}

.dot {
  font-size: 0.875rem;
  color: gray;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  font-weight: 400;
}

.data {
  color: orangered;
  font-weight: 900;
}

.link {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.link svg {
  margin-left: 0.25rem;
}

.link a {
  color: orangered;
  font-weight: 900;
  text-decoration: none;
}

.link a:hover {
  text-decoration: underline;
}

.organisation,
.school {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 900;
  padding-left: 1rem;
  padding-right: 1rem;
}

.role,
.degree,
.subject {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  padding-left: 1rem;
  padding-right: 1rem;
}

.grade {
  font-size: 1rem;
  margin-bottom: 0.5rem;

  padding-left: 1rem;
  padding-right: 1rem;
}

.dates {
  font-size: 1rem;
  color: gray;
  padding-left: 1rem;
  padding-right: 1rem;
}

.experienceContainer:not(:first-child) {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 0.05rem solid lightgray;
}

.educationContainer:not(:first-child) {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 0.05rem solid lightgray;
}

.defaultMessage {
  margin-left: 1rem;
  font-size: 1rem;
}

.disabledPencilIcon {
  cursor: not-allowed;
  opacity: 0.5;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.menu {
  font-weight: 900;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.menuContent {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.educationContent {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: -0.5rem;
}

.experienceContent {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.interests {
  width: 100%;
}

.border {
  border-bottom: 0.05rem solid lightgray;
}

.postButton {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 2rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
  border: 0.05rem solid whitesmoke;
  color: gray;
  cursor: pointer;
  width: 100%;
  margin-left: 0.5rem;
  /* margin-right: 1rem; */
  display: flex;
  height: 3rem;
  font-weight: 900;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.postSvg {
  width: 1rem !important;
  height: 1rem !important;
  margin-left: 0.25rem !important;
}

.postButton:not(.disabled):hover {
  filter: brightness(95%);
}

.mutualConnections {
  margin-top: 0.5rem;
  color: gray;
  display: flex;
  align-items: center;
}

.mutualConnections p {
  font-size: 0.875rem;
}

.mutualConnectionsImages {
  position: relative;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
}

.mutualConnectionImage {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.secondImage {
  left: 1rem;
  z-index: 1;
}

.singleImage {
  margin-right: 0.25rem;
}

.multipleImages {
  margin-right: 1.25rem;
}

.boldName {
  font-weight: 900;
  color: black;
}

.button svg {
  font-size: 0.1rem;
}

.mutualConnections {
  cursor: pointer;
}

.mutualConnections:hover .mutualConnectionText {
  text-decoration: underline;
  text-decoration-color: black;
}

.mutualConnectionText {
  color: gray;
}

.searchIcon {
  padding-bottom: 0.1rem;
  padding-right: 0.25rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button:hover:enabled {
  filter: brightness(95%);
}

.userInput {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
  border: 0.05rem solid lightgray;
  color: black;
  width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.userInput::placeholder {
  color: grey;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  padding: 0.1rem;
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.label {
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  /* margin-top: 0.25rem; */
  font-size: 0.75rem;
  font-weight: 500;
}

.countryLabel {
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.cityLabel {
  margin-bottom: 0.5rem;
  /* margin-top: 0.25rem; */
  font-size: 0.75rem;
  font-weight: 500;
}

.smallPrint {
  color: gray;
  margin-top: -0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.smallPrint .charCount {
  margin-top: 0;
}

.smallPrint p {
  font-size: 0.75rem;
  margin-bottom: 0;
}

.interestsContent {
  margin-left: 1rem;
  margin-right: 1rem;
}

.noMorePosts {
  display: flex;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.05rem solid lightgray;
  justify-content: center;
  color: gray;
}

.chevron {
  font-size: 0rem;
  margin: 0;
  padding: 0;
  margin-top: -0.25rem;
  margin-right: -0.25rem;
  margin-bottom: -0.25rem;
}

.preserveFormatting {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIndicator {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.commentLoadingIndicator {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
}

.loadingIcon {
  animation: spin 1s linear infinite;
  font-size: 1rem; /* Adjust size as needed */
  color: gray; /* Adjust color as needed */
}

.postContentWrapper {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdfControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
}

.pdfControls button {
  /* padding: 5px 10px; */
  /* background-color: #f0f0f0; */
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  cursor: pointer;
}

.pdfControls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pdfViewerContainer {
  /* width: 100%; */
  overflow-x: auto;
  overflow-y: hidden;
}

.postContentWrapper svg {
  flex-shrink: 0;
  margin-right: 0.5rem;
}

.fileLink span {
  word-break: break-word;
}

.pdfViewer {
  height: auto;
  padding-bottom: 20.16rem;
  margin-left: -0.1rem;
  margin-right: -0.1rem;

  position: relative;
}

.pdfViewer iframe {
  position: absolute;
  top: 0;
  margin-top: -0.1rem;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: none; /* This removes the border */
  outline: none; /* This removes the outline */
}

.uploadingPost {
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;
}

.cancelButton {
  cursor: pointer;
  font-weight: 900;
  color: orangered;
}

.cancelButton:hover {
  text-decoration: underline;
}

.barAndText {
  display: flex;
  align-items: center;
  width: 100%;
}

.uploadingHeader {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: gray;
  margin-bottom: 1rem;
}

.progressBarContainer {
  width: 100%;
  height: 1rem;
  background-color: whitesmoke;
  border-radius: 100rem;
  overflow: hidden;
}

.progressBarFill {
  height: 100%;
  transition: 0.2s;
  background-color: orangered;
}

.progressText {
  font-size: 1rem;
  margin-left: 1rem;
  color: gray;
}

.pdfDownloadButton {
  background-color: white;
  border: none;
  color: orangered;
  font-weight: 900;
  font-size: 0.875rem;
  padding: 0.5rem;
  cursor: pointer;
}

.pdfDownloadButton:hover {
  text-decoration: underline;
}

.postRemovedMessage {
  background-color: white;
  border: 0.05rem solid lightgray;
  border-radius: 0.5rem;
  padding: 1rem;
  z-index: 900;
}

.postRemoved {
  font-weight: 900;
  padding-bottom: 0.5rem;
  font-size: 1rem;
}

.reactorEmoji {
  margin-right: 0.25rem;
}

.reactorName {
  font-weight: 900;
  cursor: pointer;
  margin-right: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
}

.reactorName:hover {
  text-decoration: underline;
}

.postDeleted {
  font-size: 1rem;
  color: gray;
}

.reactionContainer {
  display: flex;
  flex-wrap: wrap;
}

.reactionTextWrapper {
  flex: 1;
  min-width: 0;
  overflow: hidden;
}

.reactionText {
  display: flex;
  align-items: baseline;
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reactionContainer span {
  font-size: 0.875rem;
}

.reaction {
  color: gray;
  padding: 1rem;
  display: flex;
  align-items: center;
}

.closeIcon {
  margin-bottom: 0.5rem;
  transition: 0.2s;
  /* background-color: white; */
  border-radius: 50%;
  padding: 0.5rem;

  cursor: pointer;
  z-index: 10;
}

.closeIcon:hover {
  background-color: whitesmoke;
}

.repostPopup {
  position: fixed;
  bottom: 1rem;
  left: 1rem;

  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);
  display: flex;
  align-items: center;
  z-index: 500;
  min-width: 20rem;
  /* border: 0.05rem solid lightgray; */
}

.repostPopup p {
  font-size: 0.875rem;
  margin-bottom: 0.125rem;
  margin-right: 0.25rem;
}

.repostPopupContent {
  display: flex;
  align-items: center;
}

.tickIcon {
  margin-right: 0.25rem;
  /* margin-bottom: 0.125rem; */
}

.viewRepostLink,
.viewPostLink {
  color: orangered;
  font-size: 0.875rem;
  font-weight: 900;
  margin-bottom: 0.125rem;
  text-decoration: none;
}

.viewRepostLink:hover,
.viewPostLink:hover {
  text-decoration: underline;
}

.repostCloseButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: 0.2s;
}

.repostCloseIcon {
  margin-bottom: 0.25rem;
  margin-right: -1rem;
}

@media (max-width: 990px) {
  .imageModalContent {
    width: 95vw;
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    display: block;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0rem;
    gap: 0rem;
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
  }

  .rightCards,
  .centreCards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  .centreCards {
    width: 100%;
  }

  .profileHeader {
    display: flex;
    justify-content: space-between;
  }

  .postCard {
    background-color: white;
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0.05rem solid lightgray;
    border-right: 0.5srem solid lightgray; */
  }

  .profileImage {
    margin-bottom: 1rem;
    width: 8rem; /* Adjust size as needed */
    height: 8rem; /* Adjust size as needed */
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white; /* Placeholder color, remove if you have an image */
  }

  .profileImage img {
    width: 100%;
    height: auto;
  }

  .card {
    background-color: white;
    border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0.05rem solid lightgray;
    border-right: 0.05em solid lightgray;
    margin-bottom: 1rem;
    margin-top: -1rem;
  }

  .repostPopup {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;

    box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);

    display: flex;
    align-items: center;
    z-index: 500;
    /* width: 20rem; */
  }
}

@media (max-width: 648px) {
  .uploadingPost {
    background-color: white;
    border-radius: 0rem;
    padding: 1rem;
  }

  .repostPopup {
    position: fixed;
    bottom: 5.8rem;
    left: 1rem;
    right: 1rem;

    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);

    display: flex;
    align-items: center;
    z-index: 500;

    /* border: 0.05rem solid lightgray; */
  }

  .content {
    display: block;
    padding-top: 1rem;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 4.8rem;
    /* margin-bottom: ; */
    gap: 0rem;
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
  }

  .rightCards,
  .centreCards {
    display: flex;
    flex-direction: column;
    /* justify-self: center; */
    gap: 1rem;
    width: 100%;
  }

  .centreCards {
    width: 100%;
  }

  .profileHeader {
    display: flex;
    justify-content: space-between;
  }

  .postCard {
    background-color: white;
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray; */
    border-radius: 0rem;
  }

  .experiencesCard,
  .educationCard {
    border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray;
    border-radius: 0rem;
  }

  .postImage {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white; /* Placeholder color, remove if you have an image */
  }

  .profileImage img {
    width: 100%;
    height: auto;
  }

  .card {
    background-color: white;
    border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray;
    border-radius: 0rem;
    margin-top: -1rem;
  }

  .interestsMenu {
    font-weight: 900;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .noResults {
    margin-left: 1rem;
  }

  .topCard {
    border-radius: 0rem;
  }

  .noMorePosts {
    border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray;
    border-radius: 0rem;
  }

  .uploadContainer {
    border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray;
    border-radius: 0rem;
  }

  .postRemovedMessage {
    border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray;
    border-radius: 0rem;
  }

  .noNotifications {
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray; */
    border-radius: 0rem;
  }
}
