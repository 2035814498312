.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 1.125rem;
  background-color: white;
  font-size: 1.125rem;
  border-bottom: 0.05625rem solid lightgray;
  z-index: 1000;
}

.navItems {
  display: flex;
  align-items: center;
}

.hiddenLink {
  color: white;
  font-size: 1rem;
}

.navItems a {
  text-decoration: none;
  display: flex;
}

.icon {
  width: 2.25rem;
  height: 2.25rem;
  margin-right: 0.5625rem;
  cursor: pointer;
}

.logo {
  font-size: 1.40625rem;
  letter-spacing: -0.0140625rem;
  font-weight: 900;
  color: black;
  margin-right: 6.75rem;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  top: 100%;
  padding-left: 0.5625rem;
  padding-right: 0.5625rem;
  padding-top: 0.5625rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-bottom: 0.05625rem solid lightgray;
  border-left: 0.05625rem solid lightgray;
  border-right: 0.05625rem solid lightgray;
  border-radius: 0 0 0.28125rem 0.28125rem;
  z-index: 1000;
}

.dropdown a {
  color: black;
  padding-bottom: 0.5625rem;
  text-decoration: none;
}

.dropdown a:hover {
  text-decoration: underline;
}

/* Mobile Menu */
.menuItem {
  cursor: pointer;
  display: flex;
}

.company:hover {
  text-decoration: underline;
}

.underlined {
  text-decoration: underline;
}

.menuIcon {
  margin: 0;
  padding: 0;
  display: none;
  cursor: pointer;
}

.menu {
  position: fixed;
  top: 4.51rem;
  left: 0;
  right: 0;
  bottom: 0;
  color: black;
  background-color: white;
  z-index: 999;
  display: none;
  border-top: 0.05625rem solid lightgray;
}

.menuCompany {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1.125rem;
}

.menuDropdown {
  display: flex;
  flex-direction: column;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  padding-bottom: 0.5625rem;
}

.menuDropdown a {
  color: black;
  padding-bottom: 0.5625rem;
  text-decoration: none;
}

.menuDropdown a:hover {
  text-decoration: underline;
}

.border {
  border-bottom: 0.05625rem solid lightgray;
}

.menuMessage {
  position: absolute;
  left: 1.125rem;
  right: 1.125rem;
  bottom: 8.125rem;
  display: flex;
  align-items: center;
  font-weight: 700;
}

.primaryButton {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 1.125rem;
  padding: 0.5625rem;
  background-color: orangered;
  font-size: 1.125rem;
  color: white;
  border-radius: 0.28125rem;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.05625rem solid orangered;
  cursor: pointer;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.primaryButton:hover {
  filter: brightness(95%);
}

.userInput {
  position: absolute;
  margin: 1.125rem;
  bottom: 3.719rem;
  z-index: 1000;
  right: 0;
  left: 0;
  padding: 0.5625rem;
  background-color: whitesmoke;
  font-size: 1.125rem;
  color: black;
  border-radius: 0.28125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.05625rem solid lightgray;
  outline: none;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

/* Message styles */
.success {
  font-size: 1.125rem;
  margin-top: 0.5625rem;
  color: orangered;
}

.error {
  font-size: 1.125rem;
  margin-top: 0.5625rem;
}

.message {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.icons {
  margin-top: 0.28125rem;
  margin-right: 0.28125rem;
}

@media (max-width: 1297px) {
  .menuIcon {
    display: none;
  }

  .menu {
    display: none;
  }
}

@media (max-width: 648px) {
  .nav {
    justify-content: space-between;
  }

  .menu {
    display: block;
  }

  .menuItem {
    display: none;
  }

  .menuIcon {
    display: block;
  }
}
