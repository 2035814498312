.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: white;
  z-index: 1000;
  border-bottom: 0.05rem solid lightgray;
  font-size: 1.25rem;
  justify-content: center;
  transition: 0.2s;
}

.logo {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 41rem;
  height: 2rem;

  display: flex;
  align-items: center;
}

.rightNav {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.logInButton {
  font-size: 1rem;
  font-weight: 900;
  color: black;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.foundry {
  color: black;
  font-weight: 900;
  margin-left: 0.375rem;
  font-size: 1.4rem;
  margin-right: 40.5rem;
}

.logInButton:hover {
  text-decoration: underline;
}

.joinNowButton {
  text-decoration: none;

  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 10rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: orangered;
  border: 0.05rem solid orangered;
  color: white;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.joinNowButton svg {
  margin-right: 0.25rem;
}

.joinNowButton:hover {
  filter: brightness(95%);
}

@media (max-width: 990px) {
  .nav {
    justify-content: space-between;
  }

  .icon {
    margin-right: 0rem;
  }
}
