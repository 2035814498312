.optionsContainer {
  position: relative;

  margin-left: 1rem;
}

.optionsIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  transition: 0.2s;
}

.optionsIcon:hover,
.optionsIconActive {
  background-color: whitesmoke;
}

.optionsIconDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.optionsContainer {
  opacity: 1 !important; /* Force opacity to stay at 1 */
  mix-blend-mode: normal;
  isolation: isolate;
}

.optionsIcon {
  opacity: 1 !important; /* Force opacity to stay at 1 */
  mix-blend-mode: normal;
  isolation: isolate;
}

/* Only apply reduced opacity when specifically needed */
.optionsIcon[style*="opacity: 0.5"] {
  opacity: 0.5 !important;
}
