/* 
border: 0.9px = 0.05625rem
4.5px = 0.28125rem
9px = 0.5625rem
p: 18px = 1.125rem
logo: 22.5px = 1.4063rem
27px = 1.6875rem
36px = 2.25rem
h2: 45px = 2.8125rem
h1: 54px = 3.375rem
72px = 4.5rem
108px = 6.75rem
*/

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  padding: 4.55625rem 3.375rem 0 3.375rem;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
}

@media (max-width: 648px) {
  .content {
    padding: 4.55625rem 1.125rem 0 1.125rem;
  }

  h1 {
    font-size: 2.25rem;
  }

  h2 {
    font-size: 1.6875rem;
  }
}
