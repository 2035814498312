.bodyNoScroll {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: flex-start; /* Align items to the start to keep them at the top */
  justify-content: center;
  padding-top: 2rem; /* Add padding to the top */
  z-index: 1000; /* Ensure it appears above other content */
  overflow-y: hidden;
}

.boldDate {
  margin-left: 0.25rem;
}

.modalContent {
  position: fixed;
  top: 2rem;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
  max-height: calc(100vh - 0rem);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 36rem;
  background-color: white;
  border-radius: 1rem;
  z-index: 1000;
}

.cardHeader {
  background-color: white;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;

  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.cardFooter {
  background-color: white;
  padding: 1rem;

  position: fixed;
  bottom: 0rem;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
}

.connectionsList::-webkit-scrollbar {
  display: none;
}

.connectionsList {
  display: flex;
  flex-direction: column;
  padding: 0rem 0rem 3rem 0rem; /* Add bottom padding */
  overflow-y: auto;
}

.nameContainer {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nameWrapper {
  display: inline-flex;
  max-width: 100%;
  overflow: hidden;
}

.name {
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: black;
  font-weight: 900;
  text-decoration: none;
  width: 100%;
  overflow: hidden;
}

.nameText:hover {
  text-decoration: none;
  cursor: pointer;
}

.nameText {
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 0.2s;
}

.interactions {
  display: flex;
  flex-direction: column;
  padding-top: 7.5rem;
  padding-bottom: 1rem;

  overflow-y: auto;
}

.connectionInfo .nameText:hover {
  text-decoration: underline;
  cursor: pointer;
}

.userLinkContainer {
  overflow: hidden;
  margin-top: 0.25rem;
  margin-bottom: 0rem;
}

.userLink {
  display: flex;
  align-items: center;
  max-width: 100%;
  color: orangered;
  text-decoration: none;
  font-weight: 900;
  font-size: 0.875rem;
}

.userLinkText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* flex: 1; Remove this */
  min-width: 0;
}

.userLink:hover .userLinkText {
  text-decoration: underline;
}

.userLinkIcon {
  flex-shrink: 0;
  margin-left: 0.25rem;
}

.nameAndType {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.bio {
  margin-top: 0.25rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-decoration: none;
}

.default {
  font-size: 0.875rem;

  margin-bottom: 1rem;

  color: gray;

  text-decoration: none;
}

.label {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.linkTextLabel {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.button,
.textArea {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
}

.smallPrint {
  /* text-align: center; */
  color: gray;
  margin-top: -0.25rem;
  /* margin-left: 1rem;
  margin-right: 1rem; */
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1rem;
  flex-shrink: 0;
}

.checkbox input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.checkbox input + div {
  position: relative;
  width: 100%;
  height: 100%;
  border: 0.05rem solid whitesmoke;
  border-radius: 5rem;
  background: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  pointer-events: none;
}

/* Remove hover effect for pending invites */
.checkbox input:disabled:hover + div {
  background: whitesmoke;
  filter: none;
}

/* Default hover state */
.checkbox input:not(:disabled):hover + div {
  background: whitesmoke;
  filter: brightness(95%);
}

/* Ensure pending invites always have whitesmoke background */
.checkbox input:disabled + div {
  background: whitesmoke !important;
  border: 0.05rem solid whitesmoke;
}

/* Only apply orangered for checked AND not disabled */
.checkbox input:checked:not(:disabled) + div {
  background: orangered;
  border: 0.05rem solid orangered;
}

.checkbox input:checked:not(:disabled):hover + div {
  background: orangered;
  filter: brightness(95%);
}

.checkmark {
  display: none;
  pointer-events: none;
}

.checkbox input:checked + div .checkmark {
  display: block;
}

.smallPrint .charCount {
  margin-top: 0;
}

.smallPrint p {
  font-size: 0.75rem;
  margin-bottom: 0;
  margin-top: -0.5rem;
}

.imageContainer {
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.uploadIcon {
  cursor: pointer;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.imageContainer:hover .uploadIcon {
  opacity: 1;
}

.modalContent::-webkit-scrollbar {
  display: none;
}

.closeIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  transition: 0.2s;
  cursor: pointer;

  /* margin-right: -0.625rem; */
  /* margin-bottom: -0.25rem; */
}

.closeIcon:hover {
  background-color: whitesmoke;
}

.profileButton {
  display: none;
}

.imageContainer:hover img {
  opacity: 0.5;
}

.optionsIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  transition: 0.2s;
}

.optionsIcon:hover,
.optionsIconActive {
  background-color: whitesmoke;
}

.profileImage {
  width: 4rem;
  height: 4rem;

  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.5rem;
  object-fit: cover;
  flex-shrink: 0;
}

.profileImage img {
  width: 100%;
  height: auto;
}

.connectionItem {
  display: flex;
  margin-left: 1rem;
  margin-right: 1rem;
  align-items: center;

  /* justify-content: space-between; */
  padding-top: 1rem;
  padding-bottom: 1rem;

  text-decoration: none;
  border-radius: 0;
  border-top: 0.05rem solid lightgray;
}

.connectionItem:first-child {
  border-top: none;
}

.connectionType {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 0.25rem;
  font-size: 0.875rem;
  color: gray;
  font-weight: 400;
  white-space: nowrap;
}

.noBio {
  margin-top: 0rem;
}

.textArea {
  background-color: whitesmoke;
  color: black;
  border: 0.05rem solid lightgray;
  margin-bottom: 0.5rem;
  display: flex;
  resize: none;
  height: 0;
  padding-bottom: 3.5rem;
}

.searchContainer {
  margin-top: 1rem;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.searchIcon {
  position: absolute;
  left: 0.5rem; /* Adjust as needed */
  width: 1rem;
  height: 1rem;
}

.userInput {
  padding: 0.5rem;
  padding-left: 1.75rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
  border: 0.05rem solid whitesmoke;
  color: black;
  width: 100%;
}

.userInput::placeholder {
  color: grey;
}

.noNotifications {
  padding: 1rem;
  color: gray;
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 1rem;
}

@media (max-width: 648px) {
  .repostPopup {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);

    display: flex;
    align-items: center;

    width: 20rem;
    /* border: 0.05rem solid lightgray; */
  }
}

.bold {
  font-size: 1rem;
  font-weight: 900;
  color: gray;
}

.text {
  margin-top: 0.25rem;
  font-size: 1rem;
}

.homeButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  text-decoration: none;
  margin-top: 1rem;
  transition: 0.2s;
  border-radius: 10rem;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: white;
  border: 0.05rem solid orangered;
  color: orangered;
  font-weight: 900;
  cursor: pointer;
  width: 6.5rem;
  display: flex;
  align-items: center;
}

.homeButton:hover {
  background-color: orangered;
  color: white;
}

.homeButton svg {
  margin-right: 0.25rem;
}

.button {
  background-color: orangered;
  border: 0.05rem solid orangered;
  color: white;
  border-radius: 10rem;
  font-weight: 900;
  cursor: pointer;
  width: 100%;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button:hover:enabled {
  filter: brightness(95%);
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 900;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIndicator {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.loadingIcon {
  animation: spin 1s linear infinite;
  font-size: 1rem; /* Adjust size as needed */
  color: gray; /* Adjust color as needed */
}

.buttonIcon {
  animation: spin 1s linear infinite;
  font-size: 1rem; /* Adjust size as needed */
  color: white; /* Adjust color as needed */
}

.loadingIcon,
.buttonIcon {
  padding: 0.1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.charCount {
  font-size: 0.75rem;
  color: gray;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.disabledInput {
  opacity: 0.5;
  cursor: not-allowed;
}

.optionsContainer {
  position: relative;
  margin-left: 0.5rem;
}

.optionsDropdown {
  position: absolute;
  top: 3rem;
  right: 0;
  box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);
  background-color: whitesmoke;
  border-radius: 1rem 0 1rem 1rem;
  display: flex;
  flex-direction: column;
}

.optionsDropdown button {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 1rem 0 1rem 1rem;
  padding: 0.5rem;
  color: gray;
  font-size: 1rem;
  border: none;
  font-family: "Roboto";
  z-index: 1000;
  background: none;
  transition: 0.2s;
  background-color: whitesmoke;
  cursor: pointer;
  white-space: nowrap;
}

.optionsDropdown button:hover {
  filter: brightness(95%);
}

.removeIcon {
  margin-right: 0.25rem;
}

.connectionDate {
  font-size: 0.875rem;
  color: gray;
}

.connectionItem {
  display: flex;
  align-items: center;
}

.connectionInfo {
  flex-grow: 1;
  min-width: 0;
}

.repostPopup {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);
  display: flex;
  align-items: center;
  z-index: 1100;
  width: 20rem;
  /* border: 0.05rem solid lightgray; */
}

.repostPopup p {
  font-size: 0.875rem;
  margin-bottom: 0.125rem;
  margin-right: 0.25rem;
}

.repostPopupContent {
  display: flex;
  align-items: center;
}

.tickIcon {
  margin-right: 0.25rem;
  /* margin-bottom: 0.125rem; */
}

.viewRepostLink,
.viewPostLink {
  color: orangered;
  font-size: 0.875rem;
  font-weight: 900;
  margin-bottom: 0.125rem;
  text-decoration: none;
}

.viewRepostLink:hover,
.viewPostLink:hover {
  text-decoration: underline;
}

.repostCloseButton {
  position: absolute;
  top: 0.875rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: 0.2s;
}

.repostCloseIcon {
  margin-bottom: 0.25rem;
  margin-right: -1rem;
}

@media (max-width: 991px) {
  .cardFooter {
    padding-bottom: 2rem;
  }

  .repostPopup {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;

    box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);

    display: flex;
    align-items: center;

    width: 20rem;
  }

  .modalContent {
    position: absolute;
    top: 0rem;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(0%);
    max-height: calc(100vh - 0rem);
    width: 100%;
    border-radius: 0;
    z-index: 1000;
  }

  .imageContainer:hover img {
    opacity: 1;
  }
}

@media (max-width: 648px) {
  .noNotifications {
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray; */
    border-radius: 0rem;
  }
}
