.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 1rem;
  background-color: white;
  z-index: 1000;
  border-bottom: 0.05rem solid lightgray;
  font-size: 1.25rem;
  justify-content: center;
  transition: 0.2s;
}

.icon {
  width: 2rem;
  height: 2rem;
  margin-right: 14.25rem;
  display: flex;
  align-items: center;
  /* cursor: pointer; */
  /* margin-bottom: -0.25rem; */
}

.centreIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  margin-right: 14.25rem;
  transition: 0.2s;
}

.nav a {
  display: flex;
  align-items: center;
  transition: 0.2s;
  /* width: 1.5rem; */
  /* height: 1.5rem; */
}

.profileIcon img {
  width: 2rem;
  object-fit: cover;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  display: flex;
  border-radius: 50%;
}

@media (max-width: 990px) {
  .nav {
    justify-content: space-between;
  }

  .icon {
    margin-right: 0rem;
  }

  .centreIcons {
    margin-right: 0;
  }
}

.notificationIconWrapper {
  position: relative;
  display: inline-block;
}

.notificationCount {
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  background-color: orangered;
  color: white;
  border-radius: 50%;
  padding: 0.125rem 0.25rem;
  font-size: 0.75rem;
  font-weight: 900;
  min-width: 1.125rem;
  text-align: center;
}
