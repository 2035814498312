.icon {
  width: 2.25rem;
  height: 2.25rem;
  margin-right: 0.5625rem;
  cursor: pointer;
}

.logo {
  font-size: 1.40625rem;
  letter-spacing: -0.0140625rem;
  font-weight: 900;
  color: black;
  margin-right: 6.75rem;
  cursor: pointer;
}

/* Footer styles */
footer {
  background-color: white;
  font-size: 1.125rem;
  border-top: 0.05625rem solid lightgray;
  margin-left: -3.375rem;
  margin-right: -3.375rem;
  box-sizing: border-box;
  z-index: 998;
  margin-top: 6.75rem;
  padding-bottom: 6.75rem;
  padding-top: 6.75rem;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.topFooter {
  display: flex;
  align-items: self-start;
}

.topFooter a {
  text-decoration: none;
  display: flex;
}

.footerIcon {
  display: flex;
  align-items: center;
}

.companyMenu {
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  margin-top: 0.5625rem;
  margin-right: 3.375rem;
}

.companyMenu p {
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.5625rem;
}

.companyMenu a {
  text-decoration: none;
  color: black;
  padding-bottom: 0.28125rem;
}

.companyMenu a:hover {
  text-decoration: underline;
}

.bottomFooter {
  margin-top: 6.46875rem;
  display: flex;
  align-items: self-start;
}

.bottomFooterMobile {
  display: flex;
}

.copyright {
  font-weight: 700;
  font-size: 1.125rem;
  margin-right: 3.375rem;
}

.socialMenu {
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  margin-top: 0.5625rem;
}

.socialMenu p {
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.5625rem;
}

.socialMenu a {
  text-decoration: none;
  color: black;
}

.socialMenu a:hover {
  text-decoration: underline;
}

@media (max-width: 1297px) {
  .footerInteractions {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

@media (max-width: 648px) {
  footer {
    margin-left: -1.125rem;
    margin-right: -1.125rem;
    margin-top: 6.75rem;
    padding-bottom: 6.75rem;
  }

  .topFooter {
    flex-direction: column;
  }

  .footerIcon {
    margin-bottom: 2.25rem;
  }

  .companyMenu {
    margin-top: 0rem;
  }

  .socialMenu {
    margin-top: 2.25rem;
  }

  .bottomFooter {
    flex-direction: column;
  }

  .copyright {
    margin-right: 0rem;
    margin-bottom: 2.25rem;
  }
}
