.dropdownContainer {
  position: relative;
}

.dropdownToggle {
  background: none;
  border: none;
  margin-left: 1rem;
  padding: 0;
}

.closeIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  transition: 0.2s;
  cursor: pointer;
}

.closeIcon:hover,
.dropdownContainer.active .closeIcon {
  background-color: whitesmoke;
}

.dropdownMenu {
  position: absolute;
  right: 0;
  top: 3rem;
  box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);

  background-color: whitesmoke;
  border-radius: 1rem 0 1rem 1rem;
}

.dropdownItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  font-family: "Roboto";
  border-radius: 1rem 0 1rem 1rem;
  color: gray;
  font-size: 1rem;
  background-color: whitesmoke;
  border: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s;
}

.dropdownItem svg {
  margin-right: 0.25rem;
}

.dropdownItem:hover {
  background-color: whitesmoke;

  filter: brightness(95%);
}
