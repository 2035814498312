.page {
  background-color: white;
  display: flex;
  padding-top: 4rem;
  min-height: 100vh;
}

.content {
  /* background-color: red; */
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.formContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-color: aqua; */
}

.formContent {
  background-color: white;
  width: 24rem;
  margin-left: 2rem;
  border: 0.05rem solid white;
  border-radius: 0.25rem;
  margin-top: 3rem;
  padding: 1rem;
}

.formAbout {
  margin-bottom: 2rem;
}

.primaryAbout {
  font-weight: 900;
  font-size: 1.5rem;
}

.interactions {
  display: flex;
  flex-direction: column;
  /* background-color: bisque; */
}

.highlightedItem {
  background-color: whitesmoke; /* Example hover color */
}

.label {
  font-size: 0.875rem;
  font-weight: 500;
}

.dropdownHeader {
  resize: none;
}

.button,
.dropdownHeader {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
}

.dropdownHeader::placeholder {
  color: grey;
}

.button {
  background-color: orangered;
  border: 0.05rem solid orangered;
  color: white;
  font-weight: 900;
  cursor: pointer;
  margin-top: 1rem;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button:hover:enabled {
  filter: brightness(95%);
}

.error {
  font-size: 0.75rem;
  color: black;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.errorIcon {
  font-size: 0.75rem;
  margin-right: 0.25rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  padding: 0.1rem;
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.dropdownContainer {
  position: relative;
}

.charCount {
  font-size: 0.875rem;
  color: gray;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.dropdownHeader {
  background-color: whitesmoke;
  color: black;
  width: 100%;
  border-radius: 0.5rem;
  border: 0.05rem solid whitesmoke;
}

.dropdownListContainer {
  position: absolute;
  border-radius: 0.25rem;
  background-color: white;
  border: 0.05rem solid lightgray;
  margin-top: -0.8rem;
  max-height: 11rem;
  width: 100%;
  overflow-y: auto;
  z-index: 999;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
}

.dropdownList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdownListItem {
  padding: 0.5rem;
  cursor: pointer;
}

.dropdownListItem:hover {
  background-color: whitesmoke;
}

.disabled {
  /* cursor: not-allowed; */
  opacity: 0.5;
}

@media (max-width: 648px) {
  .formContent {
    width: 100%;
    background-color: white;
    margin-left: 0rem;
    border: 0.05rem solid white;
    border-radius: 0.25rem;
    margin-top: 1rem;
    padding: 1rem;
  }

  .content {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
