.noStartupContainer {
  display: flex;
  align-items: flex-start;
}

.stripeLogo {
  padding-left: 0.25rem;
}
.startupContainer {
  display: flex;
  align-items: flex-start;
  margin-top: 0; /* Reset default margin */
  padding-top: 0; /* Reset default padding */
  border-top: none; /* Reset default border */
}

.noUserLink svg {
  margin-left: 0.25rem;
  margin-bottom: 0.0625rem;
  flex-shrink: 0;
}

.noUserLink {
  display: inline-flex;
  align-items: center;
  color: gray;
  font-weight: 900;
  text-decoration: none;
  font-size: 0.875rem;
  min-width: 0;
  margin-bottom: 0;
  margin-top: 0.25rem;
}

.noUserLink span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.matchTally {
  margin-top: 1rem;
  display: inline-flex;
  align-items: center;
  color: orangered;
  font-weight: 900;
  padding: 0.5rem;
  background-color: rgba(255, 69, 0, 0.1);
  border: solid 0.05rem transparent;
  height: 100%;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  width: fit-content;
}

.matchTally svg {
  margin-right: 0.25rem;
  flex-shrink: 0;
  display: flex;
}

.matchTally4 {
  display: inline-flex;
  align-items: center;
  color: orangered;
  font-weight: 900;
  padding: 0.5rem;
  background-color: rgba(255, 69, 0, 0.1);
  border: solid 0.05rem transparent;
  height: 100%;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  width: fit-content;
}

.matchTally4 svg {
  margin-right: 0.25rem;
  flex-shrink: 0;
  display: flex;
}

.matchTally2 {
  margin-top: 0.5rem;
  display: inline-flex;
  align-items: center;
  color: orangered;
  font-weight: 900;
  padding: 0.5rem;
  background-color: rgba(255, 69, 0, 0.1);
  border: solid 0.05rem transparent;
  height: 100%;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  width: fit-content;
}

.matchTally2 svg {
  margin-right: 0.25rem;
  flex-shrink: 0;
  display: flex;
}

.matchTally3 {
  margin-top: 1rem;
  display: inline-flex;
  align-items: center;
  color: orangered;
  font-weight: 900;
  padding: 0.5rem;
  background-color: rgba(255, 69, 0, 0.1);
  border: solid 0.05rem transparent;
  height: 100%;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  width: fit-content;
}

.matchTally3 svg {
  margin-right: 0.25rem;
  flex-shrink: 0;
  display: flex;
}

/* Apply spacing/border only to containers after the first one */
.startupContainer + .startupContainer {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 0.05rem solid lightgray;
}

/* Owner-specific styling that will override the above when present */
.ownerFirstDonation {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 0.05rem solid lightgray;
}
.originalAmount {
  color: gray;
  font-size: 1rem;
  margin-left: 0.25rem;
}

.successMessage {
  position: fixed;
  top: 20px;
  right: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 16px;
  max-width: 400px;
}

.stripeStatus {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.stripeStatusHeader {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dashboardLink {
  color: #0070f3;
  text-decoration: none;
  font-size: 14px;
}

.dashboardLink:hover {
  text-decoration: underline;
}

.successContent {
  display: flex;
  align-items: center;
  gap: 12px;
}

.successIcon {
  color: #10b981;
  width: 24px;
  height: 24px;
}

.successText {
  flex-grow: 1;
}

.successText h3 {
  margin: 0;
  color: #111827;
  font-size: 16px;
  font-weight: 600;
}

.successText p {
  margin: 4px 0 0;
  color: #6b7280;
  font-size: 14px;
}

.closeButton {
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  font-size: 20px;
  padding: 4px;
  line-height: 1;
}

.closeButton:hover {
  color: #111827;
}

.communityContainer {
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 0.05rem solid lightgray;
}

.userContainer {
  display: flex;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  gap: 0.25rem;
}

.userContainer + .userContainer {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 0.05rem solid lightgray;
}

.userImage {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 0.25rem;
}

.startupImage {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 0.25rem;
}

.userInfo {
  flex-grow: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 4rem; /* Match image height */
}

.noUserImage {
  width: 4rem;
  height: 4rem;
  margin-right: 0.25rem;
  object-fit: cover;
  border-radius: 50%;
}

.noStartupImage {
  width: 4rem;
  height: 4rem;
  margin-right: 0.25rem;
  object-fit: cover;
}

.noPostImage {
  width: 4rem;
  height: 4rem;
  margin-right: 0.5rem;
  object-fit: cover;
}

.noCommunityImage {
  width: 4rem;
  height: 4rem;
  border-radius: 0.5rem;
  margin-right: 0.25rem;
  object-fit: cover;
}

.communityImage {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 0.5rem;
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 0.25rem;
}

.eventImage {
  width: 6rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 0.5rem;
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 0.25rem;
}

.noEventImage {
  width: 6rem;
  height: 4rem;
  border-radius: 0.5rem;
  margin-right: 0.25rem;
  object-fit: cover;
}

.emptyIntroText {
  color: gray;
}

.defaultImage {
  width: 4rem;
  height: 4rem;
  background-color: whitesmoke;
  /* border-radius: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 0.5rem;
}

.emptyUserInfo {
  color: gray;
}

.userDetails {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  gap: 0.25rem;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.people {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.eventPassed {
  font-size: 1rem;
  padding: 0.5rem 0;
  border-radius: 10rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: white;
  border: 0.05rem solid white;
  color: gray;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.following {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
  border: 0.05rem solid whitesmoke;
  color: gray;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.following:hover {
  background-color: whitesmoke;
  filter: brightness(95%);
}

.button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 10rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: white;
  border: 0.05rem solid orangered;
  color: orangered;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.headlineButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 10rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: orangered;
  border: 0.05rem solid orangered;
  color: white;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.button:hover {
  background-color: orangered;
  color: white;
}

.headlineButton:hover {
  filter: brightness(95%);
}

.startupAbout:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.button svg,
.headlineButton svg,
.following svg,
.eventPassed svg {
  margin-right: 0.25rem;
  display: flex;
}

.defaultImageTwo {
  width: 4rem;
  border: 0.05rem solid lightgray;
  height: 4rem;
  background-color: whitesmoke;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.defaultImageThree {
  width: 6rem;
  border: 0.05rem solid lightgray;
  height: 4rem;
  background-color: whitesmoke;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eventImage {
  width: 6rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 0.5rem;
  cursor: pointer;
}

.startupRole {
  font-size: 1rem;
  font-weight: 900;
  color: black;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.bio {
  word-wrap: break-word; /* Add this line */
  overflow-wrap: break-word; /* Add this line */
  width: 100%;
}

.startupBio {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  word-wrap: break-word; /* Add this line */
  overflow-wrap: break-word; /* Add this line */
}

.startupDescription {
  font-size: 1rem;
  color: black;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.startupRole:hover {
  text-decoration: underline;
}

.startupDescription {
  padding-top: 1rem;
}

.startupAbout {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0; /* This ensures flex items can shrink below their content size */
}

.startupName {
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
  padding-bottom: 0.25rem;
}

.noStartupName {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  padding-top: 0.25rem;
  color: gray;
}

.noLink {
  font-size: 0.875rem;
  font-weight: 900;
  display: flex;
  align-items: center;
  color: gray;
}

.noLink svg {
  padding-left: 0.25rem;
}

.noTagline {
  font-size: 1rem;
  padding-top: 1rem;
  color: gray;
}

.noStartupRole {
  font-size: 1rem;
  display: flex;
  align-items: center;
  font-weight: 900;
  color: gray;
}

.startupFollowing {
  color: orangered;
  font-weight: 900;
  cursor: pointer;
  margin-bottom: 0;
  padding-bottom: 0;
}

.startupFollowing:hover {
  text-decoration: underline;
}

.noStartupFollowing {
  color: gray;
}

.page {
  background-color: whitesmoke;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  /* bottom: 0; */
  padding-top: 4rem;
  min-height: 100vh;
}

.noScroll {
  position: fixed;
  width: 100%;
  overflow: hidden;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIndicator {
  display: flex;
  justify-content: center;
}

.loadingIcon {
  animation: spin 1s linear infinite;
  font-size: 1rem; /* Adjust size as needed */
  color: gray; /* Adjust color as needed */
}

.loadingIcon {
  padding: 0.1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.stripeLoadingIcon {
  animation: spin 1s linear infinite;
  font-size: 1rem; /* Adjust size as needed */
  color: orangered; /* Adjust color as needed */
  padding: 0.1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.stripeLoadingIcon:hover {
  animation: spin 1s linear infinite;
  font-size: 1rem; /* Adjust size as needed */
  color: white; /* Adjust color as needed */
  padding: 0.1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.page {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.page::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.content {
  display: flex;
  padding-top: 2.375rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  gap: 2rem;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
}

.postInteractions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  color: gray;
  margin-top: -0.5rem;
}

.noPostInteractions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: gray;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.repostCount {
  color: gray;
  font-size: 0.875rem;
  cursor: pointer;
}

.repostCount:hover {
  color: orangered;
  text-decoration: underline;
}

.cardAbout {
  display: flex;
  flex-wrap: wrap;
}

.experiencesCardAbout {
  display: flex;
  flex-direction: column;
}

.educationCardAbout {
  display: flex;
  flex-direction: column;
}

.eventPassedContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: whitesmoke;
  border: solid 0.05rem transparent;
  color: gray;
  font-weight: 900;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
}

.skillContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: rgba(255, 69, 0, 0.1);
  border: solid 0.05rem transparent;
  color: orangered;
  font-weight: 900;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
}

.emptyContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.emptySkillContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: whitesmoke;
  border: solid 0.05rem whitesmoke;
  color: gray;
  font-weight: 900;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
}

.defaultContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 0.05rem solid lightgray;
  gap: 0.5rem;
}

.rightInteractions {
  display: flex;
  align-items: center;
  /* gap: 0.25rem; */
}

.posts {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.post {
  border: 0.05rem solid lightgray;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: 0.2s;
}

.noPost {
  border: 0.05rem solid lightgray;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: 0.2s;
  /* background-color: whitesmoke; */
}

.post:hover {
  background-color: whitesmoke;
}

.postContent {
  display: flex;
  align-items: center;
}

.postContent img {
  border-right: 0.05rem solid lightgray;
}

.mediaPreview {
  width: 4rem;

  height: 4rem;
  position: relative;
  overflow: hidden;
}

.mediaPreview img,
.mediaPreview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.5rem;
  height: 1.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playButton::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 0.3rem solid transparent;
  border-bottom: 0.3rem solid transparent;
  border-left: 0.5rem solid white;
  margin-left: 0.125rem; /* Slight adjustment to center the triangle */
}

.postHeader {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: gray;
  margin-bottom: 0.5rem;
  width: 100%;
  overflow: hidden;
}

.postAuthor {
  font-weight: 900;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.postMessage {
  white-space: nowrap;
  margin-left: 0.25rem;
}

.textOnlyPost {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.donationLink {
  color: orangered;
  font-weight: 900;
  text-decoration: none !important; /* Force no underline */
  display: inline;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  word-break: break-all;
  overflow-wrap: break-word;
}

.donationLink:hover {
  text-decoration: underline !important;
}

.donationLink svg {
  vertical-align: text-bottom;
  margin-left: 0.25rem;
  margin-bottom: 0.125rem;
  display: inline-block;
}

.link {
  cursor: pointer;
}

.link a,
.donationLink a {
  /* margin-bottom: 0.5rem; */
  color: orangered;
  font-weight: 900;
  text-decoration: none;
  cursor: pointer;
}

.link a:hover,
.donationLink a:hover {
  text-decoration: underline;
}

.defaultText {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  /* margin-bottom: 1rem; */
  color: gray;
}

.tagDefaultText {
  font-size: 1rem;
  color: gray;
}

.defaultTextBold {
  font-size: 1rem;
  color: gray;
  font-weight: 900;
}

.showAllPosts {
  display: flex;
  font-size: 1rem;
  margin-bottom: -1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0 0 0.75rem 0.75rem;
  border-top: 0.05rem solid lightgray;
  cursor: pointer;
  font-weight: 900;
  color: gray;
  transition: 0.2s;
}

.showAllPosts:hover,
.showAllExperiences:hover {
  /* color: black; */
  filter: brightness(95%);

  background-color: whitesmoke;
}

.showAllExperiences {
  display: flex;
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: -1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0 0 0.75rem 0.75rem;
  border-top: 0.05rem solid lightgray;
  cursor: pointer;
  font-weight: 900;
  color: gray;
  transition: 0.2s;
}

.showAllExperiences svg {
  margin-left: 0.25rem;
}

.postBorder {
  border-top: 0.05rem solid lightgray;
}

.noPostBorder {
  margin-top: 1rem;
  border-top: 0.05rem solid lightgray;
}

.showAllPosts svg {
  margin-left: 0.25rem;
}

.textPreview {
  flex: 1;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: black;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 2.9rem;
  margin: 0;
  font-size: 1rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
}

.noTextPreview {
  flex: 1;
  color: gray;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 2.9rem;
  margin: 0;
  font-size: 1rem;
  /* background-color: whitesmoke; */
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
}

/* .postInteractionCount .dot {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
} */

.repost:hover,
.commentText:hover {
  color: orangered;
  text-decoration: underline;
  cursor: pointer;
}

.commentText {
  cursor: pointer;
}

.commentText:hover {
  text-decoration: underline;
  color: orangered;
}

.repost {
  cursor: pointer;
}

.repost:hover {
  text-decoration: underline;
  color: orangered;
}

.dot {
  margin: 0 0.25rem;
}

.postInteractionCount {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: gray;
}

.dot {
  margin-left: -0.5rem;
}

.reactionsSummary {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.reactionStack {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-left: 0.25rem;
}

.emojiContent {
  display: inline-block;
  width: 100%;
  height: 100%;
  font-size: 0.875rem;
  line-height: 1;
  text-align: center;
}

.repostPopup {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);
  display: flex;
  align-items: center;
  z-index: 1100;
  width: 20rem;
  /* border: 0.05rem solid lightgray; */
}

.repostPopup p {
  font-size: 0.875rem;
  margin-bottom: 0.125rem;
  margin-right: 0.25rem;
}

.repostPopupContent {
  display: flex;
  align-items: center;
}

.tickIcon {
  margin-right: 0.25rem;
  /* margin-bottom: 0.125rem; */
}

.viewRepostLink,
.viewPostLink {
  color: orangered;
  font-size: 0.875rem;
  font-weight: 900;
  margin-bottom: 0.125rem;
  text-decoration: none;
}

.viewRepostLink:hover,
.viewPostLink:hover {
  text-decoration: underline;
}

.repostCloseButton {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: 0.2s;
}

.repostCloseIcon {
  margin-bottom: 0.25rem;
  margin-right: -1rem;
}

.reactionEmoji {
  width: 0.875rem;
  height: 0.875rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: var(--emoji-font-size, 0.875rem);
  margin-top: var(--emoji-margin-top, 0.125rem);
  margin-left: var(--emoji-margin-left, -0.25rem);
  cursor: pointer;
  line-height: 1;
}

.textEmoji {
  font-size: var(--emoji-text-size, 0.875rem);
  margin-bottom: var(--emoji-margin-bottom, 0.125rem);
}

.reactionCount {
  margin-left: var(--count-margin-left, 0.25rem);

  font-size: 0.875rem;
  color: gray;
  cursor: pointer;
}

.reactionsSummary:hover .reactionCount {
  color: orangered;
  text-decoration: underline;
}

.reactionCount:hover {
  color: orangered;
  text-decoration: underline;
}

.skillContainer svg,
.emptySkillContainer svg,
.eventPassedContainer svg {
  margin-right: 0.25rem;
  display: flex;
}

.skillText {
  word-break: break-word;
  flex: 1;
}

.introText {
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-bottom: 1rem;
}

/* .rightInteractions {
  display: flex;
  align-items: center;
  gap: 0.25rem;
} */

.noRightInteractions {
  display: flex;
  font-size: 0.875rem;
  align-items: center;
}

.rightCards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 20rem;
  position: sticky;
  top: 6rem; /* Account for the navbar height + some padding */
  height: fit-content;
  align-self: flex-start;
}

.rightCards,
.centreCards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 20rem;
}

.centreCards {
  width: 36rem;
}

.profileHeader {
  display: flex;
  justify-content: space-between;
}

.filePreview {
  width: 4rem;
  height: 4rem;
  background-color: rgba(255, 69, 0, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;
}

.filePreview svg {
  width: 2rem;
  height: 2rem;

  color: orangered;
}

.profileCard {
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 1rem;
  padding: 1rem;
}

.profileImage {
  margin-bottom: 1rem;
  width: 8rem;
  border-radius: 50%;
  height: 8rem;
  overflow: hidden;
  display: flex;
  color: white;
  font-weight: 900;
  font-size: 5rem;
  justify-content: center;
  align-items: center;
  background-color: white; /* Placeholder color, remove if you have an image */
}

.profileImage img {
  width: 100%;
  height: auto;
}

.defaultProfileImage {
  width: 8rem;
  height: 8rem;
  border-radius: 0.5rem; /* Makes the container circular */
  background-color: orangered; /* Background color for the circle */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px; /* Adjust font size as needed */
  color: white; /* Color of the initial */
}

.card {
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 1rem;
  padding: 1rem;
}

.activityCard {
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.activityCardTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;

  font-weight: 900;
  margin-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
}

.cardTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 1rem;
  color: black; /* Default color */
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 900;
  margin-top: 1rem;
  color: black; /* Default color */
}

.cardHeader2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 900;

  color: black; /* Default color */
}

.emptyCardTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 1rem;
  color: gray;
}

.experiencesCard,
.educationCard {
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 1rem;
  padding: 1rem;
}

.experiencesCardTitle,
.educationCardTitle,
.extraCardTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 1rem;
}

.extraCardTitle {
  color: gray;
}

.experiencesCardTitle div,
.educationCardTitle div,
.extraCardTitle div,
.cardTitle div {
  display: flex;
  align-items: center;
}

.closeIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  transition: 0.2s;
  cursor: pointer;
}

.closeIcon:hover,
.experienceIcon:hover {
  background-color: whitesmoke;
}

.experienceIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  transition: 0.2s;
  margin-left: 0.5rem;
}

.addIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.375rem;
  transition: 0.2s;
  cursor: pointer;
}

.addIcon:hover {
  background-color: whitesmoke;
}

.nameAndConnectionType {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.nameContainer {
  flex: 1;
  min-width: 0;
  word-break: break-word;
  margin-right: 0.5rem;
  display: flex; /* Add this line */
  flex-wrap: wrap; /* Add this line */
  align-items: baseline; /* Add this line */
}

.fullName {
  margin-right: 0.25rem;
}

.userName {
  font-size: 1rem;
  font-weight: 900;
  color: black;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userName:hover {
  text-decoration: underline;
}

.userNameContainer {
  display: flex;
  align-items: center;
  width: 100%;

  overflow: hidden;
}

.profileTitle {
  font-size: 1.5rem;
  font-weight: 900;
  word-break: break-word;
  margin-bottom: 1rem;
}

.spacing {
  margin-right: 0.375rem;
}

.location {
  margin-bottom: 0.25rem;
  color: gray;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.donationLocation {
  margin-bottom: 0.25rem;
  color: gray;
  display: flex;
  font-size: 0.875rem;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.donationMessage {
  margin-top: 1rem;
}

.numbers:hover {
  cursor: pointer;
  text-decoration: underline;
}

.modalBackdrop {
  position: fixed;
  top: 1rem;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}

.dotInfo {
  font-size: 0.75rem;
  color: gray;
  font-weight: 400;
}

.dot {
  font-size: 0.75rem;
  color: gray;
  gap: 0.25rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  font-weight: 400;
}

.data {
  color: orangered;
  font-weight: 900;
  margin-top: 0.25rem;
}

.linkText,
.donationLink {
  flex: 1;
  min-width: 0;
  /* margin-bottom: 0.5rem; */
}

.link {
  color: orangered;
  font-weight: 900;
  text-decoration: none;
  display: inline;
  word-break: break-all;
  overflow-wrap: break-word;
}

.link:hover {
  text-decoration: underline;
}

.link svg {
  vertical-align: text-bottom;
  margin-left: 0.25rem;
  margin-bottom: 0.125rem;
  display: inline-block;
}

.donationLink {
  color: orangered;
  font-weight: 900;
  text-decoration: none !important; /* Force no underline */
  display: inline;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  word-break: break-all;
  overflow-wrap: break-word;
}

.donationLink:hover {
  text-decoration: underline !important;
}

.donationLink svg {
  vertical-align: text-bottom;
  margin-left: 0.25rem;

  margin-bottom: 0.125rem;
  display: inline-block;
}

.link a,
.donationLink a {
  /* margin-bottom: 0.5rem; */
  color: orangered;
  font-weight: 900;
  text-decoration: none;
}

.link a:hover,
.donationLink a:hover {
  text-decoration: underline;
}

.introText a {
  color: orangered;
  font-weight: 900;
  text-decoration: none;
}

.introText a:hover {
  text-decoration: underline;
}

.introText pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
  margin: 0;
}

.moreButton {
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  padding: 0;
  margin-left: 0.25rem;
  display: inline;
  font-size: 1rem;
  white-space: nowrap;
}

.moreButton:hover {
  color: orangered;
  text-decoration: underline;
}

.organisation,
.school {
  font-size: 1rem;
  margin-bottom: 0.25rem;
  font-weight: 900;
}

.noSchool {
  font-size: 1rem;
  margin-bottom: 0.25rem;
  font-weight: 900;
  color: gray;
}

.noOrganisation {
  font-size: 1rem;
  margin-bottom: 0.25rem;
  font-weight: 900;
  color: gray;
}

.noRole {
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  color: gray;
}

.noNotifications {
  padding: 1rem;
  color: gray;
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 1rem;
}

.indent {
  margin-left: 0.5rem;
}

.bold {
  font-size: 1rem;
  font-weight: 900;
  color: black;
}

.cardContent {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.cardContent:first-child {
  margin-top: 0;
}
.link1 {
  color: black;
  font-weight: 900;
  text-decoration: none;
}

.link1:hover {
  text-decoration: underline;
}

.email {
  margin-top: 1rem;
}

.link2 {
  margin-top: 0.25rem;
  color: gray;
}

.icon {
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  display: flex;
  margin-right: 0.5rem;
}

.text {
  font-size: 1rem;
}

.homeButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  margin-top: 1rem;
  transition: 0.2s;
  border-radius: 10rem;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: white;
  border: 0.05rem solid orangered;
  color: orangered;
  font-weight: 900;
  cursor: pointer;
  width: 7rem;
  display: flex;
  align-items: center;
}

.homeButton:hover {
  background-color: orangered;
  color: white;
}

.homeButton svg {
  margin-right: 0.25rem;
}

.role,
.degree,
.subject {
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.noDegree,
.noSubject {
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  color: gray;
}

.grade {
  font-size: 0.875rem;
  color: gray;
  margin-bottom: 0.25rem;
}

.dates {
  font-size: 0.875rem;
  margin: 0;
  color: gray;
}

.experienceContainer,
.educationContainer,
.noExperienceContainer {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.activityContainer {
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: flex;
  margin-top: 1rem;
}

.noExperienceContainer {
  margin-top: 1rem;
}

.noExperienceContainer:last-child {
  padding-top: 1rem;
  border-top: 0.05rem solid lightgray;
}

.experienceContainer:not(:first-child) {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 0.05rem solid lightgray;
}

.educationContainer:not(:first-child) {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 0.05rem solid lightgray;
}

.noDescription {
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0;
  color: gray;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.description {
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.description a {
  text-decoration: none;
}

.description a:hover {
  text-decoration: underline;
}

.description.expanded {
  -webkit-line-clamp: unset;
  line-clamp: unset;
}

.userConnectionType {
  font-size: 0.875rem;
  font-weight: 400;
  color: gray;
  margin-left: 0.25rem;
  white-space: nowrap;
}

.connectionType {
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  color: gray;
  font-weight: 400;
  white-space: nowrap;
  align-items: center;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  position: relative;
}

.userLink:hover {
  text-decoration: underline;
}

.userBadge {
  margin-top: 1rem;
  display: inline-flex;
  align-items: center;
  color: orangered;
  font-weight: 900;
  padding: 0.5rem;
  background-color: rgba(255, 69, 0, 0.1);
  border-radius: 0.5rem;
  width: fit-content;
}

.userBadge svg {
  margin-right: 0.25rem;
  flex-shrink: 0;
  display: flex;
}

.userLink svg {
  margin-left: 0.25rem;
  margin-bottom: 0.0625rem;
  flex-shrink: 0;
}

.userLink {
  display: inline-flex;
  align-items: center;
  color: orangered;
  font-weight: 900;
  text-decoration: none;
  font-size: 0.875rem;
  min-width: 0;
  margin-bottom: 0;
  margin-top: 0.25rem;
}

.userLink span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.userBio {
  font-size: 0.875rem;
  margin-top: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.defaultMessage {
  font-size: 1rem;
}

.disabledPencilIcon {
  cursor: not-allowed;
  opacity: 0.5;
}

@media (max-width: 990px) {
  .repostPopup {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;

    box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);

    display: flex;
    align-items: center;

    width: 20rem;
  }

  .content {
    display: block;
    padding-top: 1.375rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    gap: 0rem;
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
  }

  .leftCards,
  .rightCards,
  .centreCards {
    display: flex;
    flex-direction: column;
    /* justify-self: center; */
    gap: 1rem;
    width: 100%;
  }

  .rightCards {
    padding-top: 1rem;
    /* display: none; */
  }

  .centreCards {
    width: 100%;
  }

  .profileHeader {
    display: flex;
    justify-content: space-between;
  }

  .profileCard {
    background-color: white;
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0.05rem solid lightgray;
    border-right: 0.05rem solid lightgray; */
    padding: 1rem;
  }

  .profileImage {
    margin-bottom: 1rem;
    width: 8rem; /* Adjust size as needed */
    height: 8rem; /* Adjust size as needed */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white; /* Placeholder color, remove if you have an image */
  }

  .profileImage img {
    width: 100%;
    height: auto;
  }

  .card {
    background-color: white;
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0.05rem solid lightgray;
    border-right: 0.05em solid lightgray; */
    /* border-radius: 0rem; */
    padding: 1rem;
  }
}

@media (max-width: 648px) {
  .noNotifications {
    border-radius: 0;
  }

  .repostPopup {
    position: fixed;
    left: 1rem;
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);
    display: flex;
    align-items: center;
    width: 20rem;
  }

  .modalOpen {
    bottom: 2rem;
  }

  .modalClosed {
    bottom: 5.8rem;
  }

  .content {
    display: block;
    padding-top: 1.375rem;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 1rem;
    gap: 0rem;
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
  }

  .leftCards,
  .rightCards,
  .centreCards {
    display: flex;
    flex-direction: column;
    /* justify-self: center; */
    gap: 1rem;
    width: 100%;
  }

  .centreCards {
    width: 100%;
  }

  .profileHeader {
    display: flex;
    justify-content: space-between;
  }

  .profileCard {
    background-color: white;
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray; */
    border-radius: 0rem;
    padding: 1rem;
  }

  .experiencesCard,
  .educationCard {
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray; */
    border-radius: 0rem;
  }

  .profileImage {
    margin-bottom: 1rem;
    width: 8rem; /* Adjust size as needed */
    height: 8rem; /* Adjust size as needed */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white; /* Placeholder color, remove if you have an image */
  }

  .profileImage img {
    width: 100%;
    height: auto;
  }

  .card {
    background-color: white;
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray; */
    border-radius: 0rem;
    padding: 1rem;
  }

  .activityCard {
    background-color: white;
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray; */
    border-radius: 0rem;
  }

  .showAllPosts,
  .showAllExperiences {
    border-radius: 0;
  }
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.userInput {
  padding: 0.5rem;
  padding-left: 1.75rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
  border: 0.05rem solid whitesmoke;
  color: black;
  margin-bottom: 1rem;
  width: 100%;
}

.userInput::placeholder {
  color: grey;
}

.searchIcon {
  position: absolute;
  left: 0.5rem; /* Adjust as needed */
  top: 0.625rem;
  width: 1rem;
  height: 1rem;
}
