.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: white;
  border-bottom: 0.05rem solid transparent;
  z-index: 10000;
}

.navItems a {
  display: flex;
}

.icon {
  width: 2rem;
  height: 2rem;
}
