.bodyNoScroll {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 2rem;
  z-index: 1000;
  overflow-y: hidden;
}

.nameWrapper {
  display: inline-flex;
  max-width: 100%;
  overflow: hidden;
}

.connectionType {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 0.25rem;
  font-size: 0.875rem;
  color: gray;
  font-weight: 400;
  white-space: nowrap;
}

.nameText:hover {
  text-decoration: none;
  cursor: pointer;
}

.nameText {
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 0.2s;
}

.modalContent {
  position: fixed;
  top: 2rem;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
  max-height: calc(100vh - 0rem);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 36rem;
  background-color: white;
  border-radius: 1rem;
  z-index: 1000;
}

.modalContent::-webkit-scrollbar {
  display: none;
}

.cardHeader {
  background-color: white;
  padding: 1rem;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 900;
}

.closeIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  transition: 0.2s;
  cursor: pointer;
}

.closeIcon:hover {
  background-color: whitesmoke;
}

.userLink:hover {
  text-decoration: underline;
}

.userLink {
  display: inline-flex;
  align-items: center;
  color: orangered;
  font-weight: 900;
  text-decoration: none;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  max-width: calc(
    100% - 0rem
  ); /* This ensures it stops before the options icon */
}

.userLink span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0; /* Allows the span to shrink */
  flex: 1; /* Takes up available space */
}

.userLink svg {
  margin-left: 0.25rem;
  flex-shrink: 0; /* Prevents the SVG from shrinking */
}

.startupsCardAbout {
  display: flex;
  flex-direction: column;
  padding-top: 8.125rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4rem;

  height: 100%;
  overflow-y: auto;
}

.startupsCardAbout::-webkit-scrollbar {
  display: none;
}

.startupContainer {
  padding: 1rem 0;
  border-top: 0.05rem solid lightgray;
}

.startupContainer:first-child {
  border-top: none;
  padding-top: 0;
}

.startupContent {
  display: flex;
  align-items: center;
  position: relative;
}

.searchContainer {
  margin-top: 2rem;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.searchIcon {
  position: absolute;
  left: 0.5rem; /* Adjust as needed */
  width: 1rem;
  height: 1rem;
  margin-top: -1rem;
}

.userInput {
  padding: 0.5rem;
  margin-top: -1rem;
  padding-left: 1.75rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
  border: 0.05rem solid whitesmoke;
  color: black;
  width: 100%;
}

.userInput::placeholder {
  color: grey;
}

.startupImageContainer {
  flex-shrink: 0;
  width: 6rem;
  height: 4rem;
  margin-right: 0.5rem;
  overflow: hidden;
}

.startupImage {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  object-fit: cover;
}

.defaultImage {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
}

.startupInfo {
  flex-grow: 1;
  min-width: 0;
  padding-right: 1rem;
}

.startupName {
  font-size: 1rem;
  font-weight: 900;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.role {
  font-size: 0.875rem;
  margin-top: 0.25rem;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bio {
  font-size: 0.875rem;
  margin-top: 0.25rem;
  margin-bottom: 0;
  word-wrap: break-word; /* Add this line */
  overflow-wrap: break-word; /* Add this line */
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.dates {
  font-size: 1rem;
  color: gray;
  margin: 0 0 0.25rem 0;
}

.following {
  font-size: 1rem;
  margin: 0;
}

.optionsIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  transition: 0.2s;
  cursor: pointer;
  /* Add relative positioning and z-index */
  position: relative;
  z-index: 1;
}

.optionsIcon:hover,
.optionsIconActive {
  background-color: whitesmoke;
}

.dropdownItemSingle {
  border-radius: 1rem 0 1rem 1rem;
}

.dropdownItemSingle:hover {
  background-color: whitesmoke;
  filter: brightness(95%);
}

.iconContainer {
  position: relative;
}

.dropdown {
  position: absolute;
  top: 3rem;
  right: 0;
  box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);
  background-color: whitesmoke;
  border-radius: 1rem 0 1rem 1rem;
  display: flex;
  z-index: 1000;
  flex-direction: column;
}

.dropdownItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  color: gray;
  font-size: 1rem;
  font-family: "Roboto";
  border: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s;
  background-color: whitesmoke;
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
}

.dropdownItem svg {
  margin-right: 0.25rem;
}

.dropdownItemFirst {
  z-index: 1000;

  border-radius: 1rem 0 0 0;
}

.dropdownItemLast {
  z-index: 1000;

  border-radius: 0 0 1rem 1rem;
}

.dropdownItem:hover {
  filter: brightness(95%);
}

.loadingIndicator {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.loadingIcon {
  animation: spin 1s linear infinite;
  font-size: 1rem;
  color: gray;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.noNotifications {
  color: gray;
  background-color: white;
  border-radius: 1rem;
  text-align: center;
  padding: 1rem;
}

.bold {
  font-size: 1rem;
  font-weight: 900;
  color: gray;
}

.text {
  margin-top: 0.25rem;
  font-size: 1rem;
}

.homeButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  text-decoration: none;
  margin: 1rem auto 0;
  transition: 0.2s;
  border-radius: 10rem;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: white;
  border: 0.05rem solid orangered;
  color: orangered;
  font-weight: 900;
  cursor: pointer;
  width: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homeButton:hover {
  background-color: orangered;
  color: white;
}

.homeButton svg {
  margin-right: 0.25rem;
}

@media (max-width: 991px) {
  .modalContent {
    position: absolute;
    top: 0rem;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(0%);
    max-height: calc(100vh - 0rem);
    width: 100%;
    border-radius: 0;
  }
}

@media (max-width: 648px) {
  .noNotifications {
    border-radius: 0;
  }
}
