.bodyNoScroll {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 2rem;
  z-index: 1000;
  overflow-y: hidden;
}

.modalContent {
  position: fixed;
  top: 2rem;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
  max-height: calc(100vh - 0rem);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 36rem;
  background-color: white;
  border-radius: 1rem;
  z-index: 1000;
}

.cardHeader {
  background-color: white;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;

  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 900;
}

.closeIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  transition: 0.2s;
  cursor: pointer;
}

.closeIcon:hover {
  background-color: whitesmoke;
}

.searchContainer {
  margin-top: 2rem;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.searchIcon {
  position: absolute;
  left: 0.5rem; /* Adjust as needed */
  width: 1rem;
  height: 1rem;
  margin-top: -1rem;
}

.userInput {
  padding: 0.5rem;
  margin-top: -1rem;
  padding-left: 1.75rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
  border: 0.05rem solid whitesmoke;
  color: black;
  width: 100%;
}

.userInput::placeholder {
  color: grey;
}

.educationsCardAbout {
  display: flex;
  flex-direction: column;
  padding-top: 8.125rem;
  padding-left: 1rem;
  padding-right: 1rem;

  padding-bottom: 4rem;
  overflow-y: auto;
}

.educationsCardAbout::-webkit-scrollbar {
  display: none;
}

.noEducationMessage {
  font-size: 1rem;
  padding: 2rem 1rem 1rem 1rem;
}

.educationContainer {
  padding: 1rem 0;
  border-top: 0.05rem solid lightgray;
}

.educationContainer:first-child {
  border-top: none;
  padding-top: 0;
}

.educationContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.educationInfo {
  flex-grow: 1;
  min-width: 0;
  margin-right: 0.5rem;
}

.school {
  font-size: 1rem;
  font-weight: 900;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.degree,
.subject,
.grade {
  font-size: 1rem;
  margin-bottom: 0.25rem;
  word-wrap: break-word; /* Add this line */
  overflow-wrap: break-word; /* Add this line */
}

.description {
  font-size: 1rem;
  margin-bottom: 0;
  margin-top: 1rem;
  word-wrap: break-word; /* Add this line */
  overflow-wrap: break-word; /* Add this line */
}

.grade {
  color: gray;
}
.dates {
  font-size: 1rem;
  margin: 0;

  color: gray;
}

.noNotifications {
  color: gray;
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 1rem;
}

.bold {
  font-size: 1rem;
  font-weight: 900;
  color: gray;
}

.text {
  margin-top: 0.25rem;
  font-size: 1rem;
}

.homeButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  text-decoration: none;
  margin-top: 1rem;
  transition: 0.2s;
  border-radius: 10rem;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: white;
  border: 0.05rem solid orangered;
  color: orangered;
  font-weight: 900;
  cursor: pointer;
  width: 7rem;
  display: flex;
  align-items: center;
}

.homeButton:hover {
  background-color: orangered;
  color: white;
}

.homeButton svg {
  margin-right: 0.25rem;
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
}

.iconContainer {
  position: relative;
}

.optionsIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  transition: 0.2s;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIndicator {
  display: flex;
  justify-content: center;
}

.loadingIcon {
  animation: spin 1s linear infinite;
  font-size: 1rem; /* Adjust size as needed */
  color: gray; /* Adjust color as needed */
}

.loadingIcon {
  padding: 0.1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.optionsIcon:hover,
.optionsIconActive {
  background-color: whitesmoke;
}

.dropdown {
  position: absolute;
  top: 3rem;
  right: 0;
  box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);

  background-color: whitesmoke;
  border-radius: 1rem 0 1rem 1rem;

  display: flex;
  z-index: 1000;
  flex-direction: column;
}

.dropdownItem svg {
  margin-right: 0.25rem;
}

.dropdownItemFirst {
  border-radius: 1rem 0 0 0;
}

.dropdownItemLast {
  border-radius: 0 0 1rem 1rem;
}

.dropdownItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  font-family: "Roboto";

  color: gray;
  font-size: 1rem;
  border: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s;
  background-color: whitesmoke;
}

.dropdownItem:hover {
  /* background-color: whitesmoke; */
  filter: brightness(95%);
}

.modalContent::-webkit-scrollbar {
  display: none;
}

.educationContainer::-webkit-scrollbar {
  display: none;
}

@media (max-width: 991px) {
  .modalContent {
    position: absolute;
    top: 0rem;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(0%);
    max-height: calc(100vh - 0rem);
    width: 100%;
    border: 0rem;
    border-radius: 0;
    z-index: 1000;
  }
}

@media (max-width: 648px) {
  .noNotifications {
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray; */
    border-radius: 0rem;
  }
}
