.card {
  background-color: white;
  /* border: 0.05rem solid orangered; */
  border-radius: 1rem;
  padding: 1rem;
}

.message {
  font-weight: 900;
  color: gray;
  margin-bottom: 0.5rem;
}

.emailMessage {
  color: gray;

  padding-bottom: 1rem;
  border-bottom: 0.05rem solid lightgray;
}

.alphaContent {
  display: flex;
  font-size: 0.875rem;
  flex-direction: column;
}

.betaContent {
  display: flex;
  font-size: 0.875rem;
  padding-top: 1rem;
  flex-direction: column;
}

.shareButtons {
  display: flex;
  align-items: center;
}

.productHunt {
  width: 10.25rem;
  margin: 0;
  padding: 0;
  /* border: 0.05rem solid #dbe1eb; */
  margin-left: 0.5rem;
  margin-top: 1.25rem;

  /* border-radius: 90rem; */
}

.email {
  font-weight: 900;
  cursor: pointer;
  color: orangered;
  display: inline-flex;
  align-items: center;
}

.interactionButton {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: white;
  border: 0.05rem solid orangered;
  color: orangered;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 10rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: white;
  border: 0.05rem solid orangered;
  color: orangered;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  margin-top: 1rem;
  width: fit-content;
}

.following {
  width: fit-content;
  margin-top: 1rem;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
  border: 0.05rem solid whitesmoke;
  color: gray;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.following:hover {
  background-color: whitesmoke;
  filter: brightness(95%);
}

.button:hover {
  background-color: orangered;
  color: white;
  filter: brightness(95%);
}

.button svg,
.following svg {
  margin-right: 0.25rem;
  display: flex;
}

.email svg {
  margin-left: 0.125rem;
}

.email:hover {
  text-decoration: underline;
}

.cardContent {
  margin-top: 1rem;

  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
  color: gray;
  align-items: center;
  gap: 0.25rem;
}

.cardContent a {
  color: gray;
  text-decoration: none;
}

.cardContent a:hover {
  color: orangered;
  text-decoration: underline;
}

.dot {
  /* margin-left: 0.25rem; */
  /* margin-right: 0.25rem; */
  font-size: 0.875rem;
  color: gray;
}

.icon {
  width: 1rem;
  height: 1rem;
}

.foundry {
  margin-left: 0rem;
  font-weight: 900;
  font-size: 0.875rem;
  color: black;
}

.copyright {
  margin-left: 0.25rem;
  font-size: 0.875rem;
  color: black;
}

.company {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

@media (max-width: 648px) {
  .card {
    border-radius: 0;
    border-left: 0rem;
    border-right: 0rem;
  }
}
