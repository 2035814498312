.leftImage {
  margin-right: 6.75rem;
  width: 33.75rem;
  /* background-color: aqua; */
}

.rightImage {
  margin-left: 6.75rem;
  width: 33.75rem;
  /* background-color: aqua; */
}

.title {
  color: orangered;
  margin-bottom: 0.5625rem;
}

.headerAbout {
  width: 33.75rem;
  /* background-color: azure; */
}

/* Content styles */
.contentHeader {
  margin-top: 6.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: bisque; */
}

.rocketImage {
  margin-right: 6.75rem;
  width: 33.75rem;
  /* background-color: aqua; */
}

.contentP {
  margin-bottom: 0rem;
}

.foundry {
  font-weight: 900;
  letter-spacing: -0.0140625rem;
}

.featureWord {
  font-weight: 700;
  color: orangered;
}

/* Message styles */
.success {
  font-size: 1.125rem;
  margin-top: 0.5625rem;
  color: orangered;
}

.error {
  font-size: 1.125rem;
  margin-top: 0.5625rem;
}

.message {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.icons {
  margin-top: 0.28125rem;
  margin-right: 0.28125rem;
}

.email,
.button {
  padding: 0.5625rem;
  font-size: 1.125rem;
  border-radius: 0.28125rem;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.email {
  background-color: white;
  color: black;
  border: 0.05625rem solid lightgray;
  outline: none;
  width: 72.78%;
  display: flex;
}

.button {
  background-color: orangered;
  border: 0.05625rem solid orangered;
  color: white;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  filter: brightness(95%);
}

.footerInteractions {
  display: flex;
  gap: 1.125rem;
  margin-top: 3.375rem;
}

@media (max-width: 1297px) {
  .headerAbout {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .footerInteractions {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .email {
    width: 100%;
  }

  .contentHeader {
    flex-direction: column;
  }

  .leftImage {
    margin-right: 0rem;
  }

  .rightImage {
    margin-left: 0rem;
  }

  .rocketImage {
    margin-right: 0rem;
    margin-top: 3.375rem;
  }

  .textContainer {
    order: -1;
  }

  .contentP {
    margin-bottom: 3.375rem;
  }
}

@media (max-width: 648px) {
  .content {
    right: 1.125rem;
    left: 1.125rem;
  }

  .headerAbout {
    width: 100%;
  }

  .email {
    width: 100%;
  }

  .leftImage {
    display: flex;
    width: 100%;
  }

  .rightImage {
    display: flex;
    width: 100%;
  }

  .rocketImage {
    display: flex;
    width: 100%;
  }

  .contentP {
    font-size: 1.125rem;
  }
}
