/* 
border: 0.9px = 0.05625rem
4px = 0.25rem
8px = 0.5rem
12px = 0.75rem
16px = 1rem
20px = 1.25rem
24px = 1.5rem
28px = 1.75rem
32px = 2rem
36px = 2.25rem
40px = 2.5rem
44px = 2.75rem
48px = 3rem
52px = 3.25rem
56px = 3.5rem
60px = 3.75rem
64px = 4rem
68px = 4.25rem
72px = 4.5rem
76px = 4.75rem
80px = 5rem
84px = 5.25rem
88px = 5.5rem
92px = 5.75rem
96px = 6rem
*/

.googleIcon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.page {
  background-color: white;
  display: flex;
  padding-top: 4rem;
  min-height: 100vh;
}

.content {
  /* background-color: red; */
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.formContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-color: aqua; */
}

.formContent {
  width: 20rem;
  border: 0.05rem solid transparent;
  border-radius: 0.75rem;
  margin-top: 2rem;
  padding: 1rem;
}

.repostPopup {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);
  display: flex;
  align-items: center;
  z-index: 500;
  width: 20rem;
  /* border: 0.05rem solid lightgray; */
}

.repostPopup p {
  font-size: 0.875rem;
  margin-bottom: 0.125rem;
  margin-right: 0.25rem;
}

.repostPopupContent {
  display: flex;
  align-items: center;
}

.repostCloseButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: 0.2s;
}

.repostCloseIcon {
  margin-bottom: 0.25rem;
  margin-right: -1rem;
}

.tickIcon {
  margin-right: 0.25rem;
  /* margin-bottom: 0.125rem; */
}

.closeIcon {
  margin-bottom: 0.5rem;
  transition: 0.2s;
  /* background-color: white; */
  border-radius: 50%;
  padding: 0.5rem;

  cursor: pointer;
  z-index: 10;
}

.closeIcon:hover {
  background-color: whitesmoke;
}

.formAbout {
  margin-bottom: 1rem;
}

.primaryAbout {
  font-weight: 900;
  font-size: 1.5rem;
}

.interactions,
.altSection {
  display: flex;
  flex-direction: column;
  /* background-color: bisque; */
}

.altSection {
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-bottom: 0.05rem solid lightgray;
}

.label {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  font-size: 0.875rem;
}

.email,
.button,
.altEmail {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
}

.email {
  background-color: whitesmoke;
  color: black;
  border: 0.05rem solid whitesmoke;
}

.email::placeholder {
  color: grey;
}

.altEmail {
  border-radius: 10rem;
  background-color: white;
  color: gray;
  border: 0.05rem solid lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 400;
  margin-bottom: 2rem;
  transition: 0.2s;
}

.altEmail:hover {
  background-color: whitesmoke;
}

/* .border {
  border-bottom: 0.05rem solid lightgray;
  margin-top: 1rem;
} */

.button {
  background-color: orangered;
  border: 0.05rem solid orangered;
  color: white;
  font-weight: 900;
  cursor: pointer;
  border-radius: 10rem;
  margin-top: 2rem;
  transition: 0.2s;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button:hover:enabled {
  filter: brightness(95%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  padding: 0.1rem;
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.account {
  color: gray;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  font-size: 0.875rem;
}

.buttonMessage {
  color: gray;
  margin-top: 1rem;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 0;
}

.buttonMessage p {
  font-size: 0.875rem;
  margin-bottom: 0;
}

.smallPrint {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
}

.link {
  margin-left: 0.25rem;
  color: orangered;
  text-decoration: none;
  cursor: pointer;
  font-weight: 900;
}

.link:hover {
  text-decoration: underline;
}

.error {
  font-size: 0.75rem;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
}

.errorIcon {
  font-size: 0.75rem;
  margin-right: 0.25rem;
}

.forgotPassword {
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-end;
  color: orangered;
  text-decoration: none;
  cursor: pointer;
  font-weight: 900;
  font-size: 0.875rem;
}

.forgotPassword:hover {
  text-decoration: underline;
}

@media (max-width: 990px) {
  .repostPopup {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;

    box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);

    display: flex;
    align-items: center;
    z-index: 500;
    width: 20rem;
  }
}

@media (max-width: 648px) {
  .smallPrint {
    width: calc(100% - 2rem);
  }

  .formContent {
    width: 100%;
    background-color: white;
    margin-left: 0rem;
    border: 0.05rem solid white;
    border-radius: 0.25rem;
    margin-top: 1rem;
    padding: 1rem;
  }

  .content {
    /* background-color: red; */
    width: 100%;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
