.illustrationOne {
  margin-left: 6.75rem;
  width: 33.75rem;
  /* background-color: aqua; */
}

.page {
  position: absolute;
  top: 4.55625rem;
  right: 0rem;
  left: 0rem;
  bottom: 0;
  display: flex;
  flex-direction: column;
  bottom: 0;
  background-color: whitesmoke;
}

.content {
  position: absolute;
  top: 0rem;
  right: 3.375rem;
  left: 3.375rem;
  bottom: 0;
  display: flex;
  flex-direction: column;
  bottom: 0;
}

.headerAbout {
  width: 33.75rem;
  /* background-color: azure; */
}

.foundry {
  font-weight: 900;
  letter-spacing: -0.0140625rem;
}

.careersAbout {
  margin-top: 1.125rem;
  margin-bottom: 3.375rem;
}

.careersInteractions {
  display: flex;
  flex-direction: column;
  gap: 1.125rem;
}

.careersInteractions input {
  width: 100%;
}

.messageInput {
  padding-left: 0.5625rem;
  padding-right: 0.5625rem;
  padding-top: 0.5625rem;
  padding-bottom: 6.75rem;
  font-size: 1.125rem;
  border-radius: 0.28125rem;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  background-color: white;
  color: black;
  border: 0.05625rem solid lightgray;
  outline: none;
  display: flex;
  resize: vertical;
}

.careersHeader {
  margin-top: 6.75rem;
  display: flex;
  align-items: self-start;
  justify-content: center;
  /* background-color: bisque; */
}

.interactions {
  display: flex;
  gap: 1.125rem;
}

.email,
.button {
  padding: 0.5625rem;
  font-size: 1.125rem;
  border-radius: 0.28125rem;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.email {
  background-color: white;
  color: black;
  border: 0.05625rem solid lightgray;
  outline: none;
  width: 72.78%;
  display: flex;
}

.button {
  background-color: orangered;
  border: 0.05625rem solid orangered;
  color: white;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  filter: brightness(95%);
}

.success {
  font-size: 1.125rem;
  margin-top: 0.5625rem;
  color: orangered;
}

.error {
  font-size: 1.125rem;
  margin-top: 0.5625rem;
}

.message {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.icons {
  margin-top: 0.28125rem;
  margin-right: 0.28125rem;
}

@media (max-width: 1297px) {
  .headerAbout {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .careersInteractions {
    width: 100%;
  }

  .careersHeader {
    margin-top: 3.375rem;
    flex-direction: column;
    align-items: center;
  }

  .illustrationOne {
    margin-left: 0;
    margin-top: 3.375rem;
  }

  .interactions {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .email {
    width: 100%;
  }
}

@media (max-width: 648px) {
  .content {
    right: 1.125rem;
    left: 1.125rem;
  }

  .careersAbout {
    font-size: 1.125rem;
  }

  .headerAbout {
    width: 100%;
  }

  .illustrationOne {
    width: 100%;
  }
}
