.page {
  background-color: white;
  display: flex;
  padding-top: 4rem;
  min-height: 100vh;
}

.content {
  /* background-color: red; */
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.formContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-color: aqua; */
}

.formContent {
  width: 20rem;
  border: 0.05rem solid transparent;
  border-radius: 0.75rem;
  margin-top: 2rem;
  padding: 1rem;
}

.formAbout {
  margin-bottom: 2rem;
}

.primaryAbout {
  font-weight: 900;
  font-size: 1.5rem;
}

.interactions {
  display: flex;
  flex-direction: column;
  /* background-color: bisque; */
}

.highlightedItem {
  background-color: whitesmoke; /* Example hover color */
}

.label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.label.disabled {
  opacity: 0.5;
}

.dropdownHeader.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button,
.dropdownHeader {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
}

.dropdownHeader::placeholder {
  color: grey;
}

.button {
  border-radius: 10rem;

  background-color: orangered;
  border: 0.05rem solid orangered;
  color: white;
  font-weight: 900;
  cursor: pointer;
  margin-top: 1rem;
  transition: 0.2s;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button:hover:enabled {
  filter: brightness(95%);
}

.error {
  font-size: 0.75rem;
  color: black;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.errorIcon {
  font-size: 0.75rem;
  margin-right: 0.25rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  padding: 0.1rem;
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.dropdownContainer {
  position: relative;
}

.dropdownHeader {
  background-color: whitesmoke;
  color: black;
  width: 100%;
  z-index: 1100;
  border-radius: 0.5rem;
  border: none;
  margin-bottom: 1rem;
}

.dropdownHeader.active {
  box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);
  border-radius: 0.5rem 0.5rem 0 0;
}

.dropdownListContainer {
  position: absolute;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  background-color: whitesmoke;
  border-top: 0.05rem solid lightgray;
  margin-top: -1.04rem;
  max-height: 11rem;
  box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);

  width: 100%;
  overflow-y: auto;
  z-index: 10;
  transition: 0.2s;
}

.dropdownListContainer::-webkit-scrollbar {
  display: none;
}

.inputWrapper {
  position: relative;
}

.dropdownIcon.disabled {
  opacity: 0.5;
}

.dropdownIcon {
  color: gray;
  position: absolute;
  right: 0.375rem; /* Adjust as needed */
  top: 0.625rem;
  pointer-events: none; /* Allows clicks to pass through to the input */
}

.chevron {
  color: gray;
  position: absolute;
  right: 0.25rem; /* Adjust as needed */
  top: 0.375rem;
  pointer-events: none; /* Allows clicks to pass through to the input */
}

.dropdownList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdownListItem {
  padding: 0.5rem;
  cursor: pointer;
  transition: 0.2s;
}

.dropdownListItem:hover,
.dropdownListItem.highlightedItem {
  background-color: whitesmoke;
  filter: brightness(95%);
}

.disabled {
  /* cursor: not-allowed; */
  opacity: 0.5;
}

.repostPopup {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);
  display: flex;
  align-items: center;
  z-index: 500;
  width: 20rem;
  /* border: 0.05rem solid lightgray; */
}

.repostPopup p {
  font-size: 0.875rem;
  margin-bottom: 0.125rem;
  margin-right: 0.25rem;
}

.repostPopupContent {
  display: flex;
  align-items: center;
}

.repostCloseButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: 0.2s;
}

.repostCloseIcon {
  margin-bottom: 0.25rem;
  margin-right: -1rem;
}

.tickIcon {
  margin-right: 0.25rem;
  /* margin-bottom: 0.125rem; */
}

.closeIcon {
  margin-bottom: 0.5rem;
  transition: 0.2s;
  /* background-color: white; */
  border-radius: 50%;
  padding: 0.5rem;

  cursor: pointer;
  z-index: 10;
}

.closeIcon:hover {
  background-color: whitesmoke;
}

@media (max-width: 990px) {
  .repostPopup {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;

    box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);

    display: flex;
    align-items: center;
    z-index: 500;
    width: 20rem;
  }
}

@media (max-width: 648px) {
  .formContent {
    width: 100%;
    background-color: white;
    margin-left: 0rem;
    border: 0.05rem solid white;
    border-radius: 0.25rem;
    margin-top: 1rem;
    padding: 1rem;
  }

  .content {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
