.bodyNoScroll {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.modalBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
  height: 100%;
}

.modalContent {
  position: fixed;
  top: 2rem;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
  max-height: calc(100vh - 0rem);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 36rem;
  background-color: white;
  border-radius: 1rem;
  z-index: 1000;
}

.interactions::-webkit-scrollbar {
  display: none;
}

.cardHeader {
  background-color: white;

  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.scrollableContent {
  flex-grow: 1;
  overflow-y: hidden; /* Change this from auto to hidden */
  display: flex;
  flex-direction: column;
}

.cardFooter {
  background-color: white;
  padding: 1rem;
  position: fixed;
  bottom: 0rem;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
}

/* Update the .textArea class (which should be applied to the textarea in SkillsDropdown) */
.textArea {
  background-color: whitesmoke;
  color: black;
  border: 0.05rem solid lightgray;

  display: flex;
  resize: none;
  min-height: 3.5rem;
  padding: 0.5rem;

  flex-grow: 1;
  overflow-y: auto;
}

.maxHeight {
  margin-top: -1rem;

  min-height: 100vh;
}

.skillsDropdownWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.interactions {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Add this to make it grow and fill available space */
  overflow-y: auto; /* Change this from auto to hidden */
  padding-bottom: 6.75rem;
}

.label {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.linkTextLabel {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.userInput,
.button,
.textArea {
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
}

.infoIcon {
  margin-bottom: 0.5rem;
  margin-right: 0.25rem;
}

.smallPrint {
  color: gray;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.smallPrint .charCount {
  margin-top: 0;
}

.smallPrint p {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.imageContainer {
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.uploadIcon {
  cursor: pointer;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.imageContainer:hover .uploadIcon {
  opacity: 1;
}

.profileButton {
  display: none;
}

.imageContainer:hover img {
  opacity: 0.5;
}

.profileImage {
  margin-bottom: 1rem;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  color: white;
  font-weight: 900;
  font-size: 5rem;
  justify-content: center;
  align-items: center;
  background-color: white; /* Placeholder color, remove if you have an image */
}

.profileImage img {
  width: 100%;
  height: auto;
}

.textArea {
  background-color: whitesmoke;
  color: black;
  border: 0.05rem solid lightgray;
  margin-bottom: 0.5rem;
  display: flex;
  resize: none;
  height: 0;
  padding-bottom: 3.5rem;
}

.userInput {
  background-color: whitesmoke;
  color: black;
  /* border: 0.05rem solid lightgray; */
  margin-bottom: 0.5rem;
}

.closeIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.375rem;
  cursor: pointer;

  transition: 0.2s;
  /* margin-right: -0.625rem; */
  /* margin-bottom: -0.25rem; */
}

.closeIcon:hover {
  background-color: whitesmoke;
}

.userInput::placeholder {
  color: grey;
}

.button {
  background-color: orangered;
  border: 0.05rem solid orangered;
  color: white;
  font-weight: 900;
  cursor: pointer;
  width: 100%;
  padding: 0.5rem;
  border-radius: 10rem;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button:hover:enabled {
  filter: brightness(95%);
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 900;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIndicator {
  display: flex;
  justify-content: center;
}

.loadingIcon {
  animation: spin 1s linear infinite;
  font-size: 1rem; /* Adjust size as needed */
  color: gray; /* Adjust color as needed */
}

.loadingIcon {
  padding: 0.1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.charCount {
  font-size: 0.75rem;
  color: gray;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.disabledInput {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 991px) {
  .modalContent {
    top: 0rem;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(0%);
    max-height: calc(100vh - 0rem);
    width: 100%;
    border-radius: 0;
    z-index: 1000;
  }

  .imageContainer:hover img {
    opacity: 1;
  }

  .cardFooter {
    padding-bottom: 2rem;
  }

  .interactions {
    padding-bottom: 7.75rem;
  }
}
