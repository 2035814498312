.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, sans-serif;
}

.totalUsers {
  background: linear-gradient(135deg, orange 0%, orangered 100%);
  color: white;
  padding: 2rem;
  border-radius: 12px;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.totalUsers h2 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
  opacity: 0.9;
}

.count {
  font-size: 3rem;
  font-weight: 700;
  margin-top: 0.5rem;
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.statsCard {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #eee;
}

.statsCard h3 {
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
  color: #333;
  font-weight: 600;
}

.statsList {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.statsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
}

.statsRow:last-child {
  border-bottom: none;
}

.locationName {
  color: #333;
  font-weight: 500;
}

.badge {
  background: #f0f7ff;
  color: #0062ff;
  padding: 0.25rem 0.75rem;
  border-radius: 999px;
  font-size: 0.875rem;
  font-weight: 600;
}

.loading {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.error {
  background: #fff1f0;
  color: #ff4d4f;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .statsGrid {
    grid-template-columns: 1fr;
  }

  .totalUsers {
    padding: 1.5rem;
  }

  .count {
    font-size: 2.5rem;
  }
}

/* Add to your existing UserStats.module.css */
.statsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

@media (min-width: 1200px) {
  .statsGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Add to your existing UserStats.module.css */
.growthMetrics {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.growthMetric {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.growthMetric span:first-child {
  font-size: 0.875rem;
  opacity: 0.8;
}

.growthMetric span:last-child {
  font-size: 1.25rem;
  font-weight: 600;
}

.statsInfo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.percentage {
  font-size: 0.875rem;
  color: #666;
}

.positiveGrowth {
  font-size: 0.875rem;
  color: #10b981;
}

.negativeGrowth {
  font-size: 0.875rem;
  color: #ef4444;
}
