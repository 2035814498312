.bodyNoScroll {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.modalBackdrop {
  position: absolute;
  top: -2rem;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: flex-start; /* Align items to the start to keep them at the top */
  justify-content: center;
  padding-top: 2rem; /* Add padding to the top */
  z-index: 1000; /* Ensure it appears above other content */
  overflow-y: hidden;
}

.modalContent {
  position: fixed;
  top: 2rem;
  left: 50%;
  bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  transform: translateX(-50%);
  max-height: calc(100vh - 0rem);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 36rem;
  background-color: white;
  border-radius: 1rem;
  z-index: 1000;
}

.reactionsList::-webkit-scrollbar {
  display: none;
}

.cardHeader {
  background-color: white;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-right: 2rem;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 900;
}

.emojiSummary {
  display: flex;
  justify-content: flex-start;
  padding: 1rem;
  border-bottom: 0.05rem solid lightgray;
}

.emojiCount {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.emoji {
  font-size: 1.5rem;
  margin-right: 0.25rem;
}

.count {
  font-size: 1rem;
  font-weight: bold;
}

.searchContainer {
  margin: 1rem;
}

.userInput {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
  border: 0.05rem solid lightgray;
  color: black;
  width: 100%;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.loadingIcon {
  font-size: 1rem;
  color: gray;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.closeIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.375rem;
  cursor: pointer;

  transition: 0.2s;
  /* margin-right: -0.625rem; */
  /* margin-bottom: -0.25rem; */
}

.closeIcon:hover {
  background-color: whitesmoke;
}

.userInput::placeholder {
  color: grey;
}

.reactionsList {
  display: flex;
  padding-top: 4.25rem;
  padding-bottom: 1rem;
  flex-direction: column;
  overflow-y: auto;
}

.userEmoji {
  position: absolute;
  bottom: 0.125rem;
  right: 0.125rem;
  border-radius: 50%;
  background: none;
  font-size: var(--emoji-font-size-modal, 1rem);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
}

.emojiContent {
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 1;
  text-align: center;
  margin-top: var(--emoji-margin-top-modal, 0.09rem);
}

.userLink:hover {
  text-decoration: underline;
}

.userLink {
  display: inline-flex;
  align-items: center;
  color: orangered;
  font-weight: 900;
  text-decoration: none;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  max-width: calc(
    100% - 0rem
  ); /* This ensures it stops before the options icon */
}

.userLink span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0; /* Allows the span to shrink */
  flex: 1; /* Takes up available space */
}

.userLink svg {
  margin-left: 0.25rem;
  flex-shrink: 0; /* Prevents the SVG from shrinking */
}

.reactionItem {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: none;
  border-radius: 0;
  border-top: 0.05rem solid lightgray;
}

.reactionItem:first-child {
  padding-top: 0;

  border-top: none;
}

.userEmojiContainer {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.profileImage {
  width: 4rem;
  height: 4rem;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  object-fit: cover;
  flex-shrink: 0;
}

.userInfo {
  flex-grow: 1;
  min-width: 0;
  overflow: hidden;
}

.reactionTime {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: gray;
}

.nameWrapper {
  display: inline-flex;
  max-width: 100%;
  overflow: hidden;
}

.nameText {
  cursor: pointer;
}

.name {
  font-size: 1rem;
  color: black;
  font-weight: 900;
  text-decoration: none;
  width: 100%;
  overflow: hidden;
}

.nameText:hover {
  text-decoration: underline;
}

.nameText {
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 0.2s;
}

.connectionType {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-left: 0.25rem;
  font-size: 0.875rem;
  color: gray;
  font-weight: 400;
  white-space: nowrap;
}

.bio {
  margin-top: 0.25rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-decoration: none;
}

.noBio {
  margin-top: 0rem;
}

.default {
  font-size: 1rem;
  margin-left: 1rem;
  color: gray;
  text-decoration: none;
}

.noNotifications {
  padding: 1rem;
  color: gray;
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 1rem;
}

.bold {
  font-size: 1rem;
  font-weight: 900;
  color: gray;
}

.text {
  margin-top: 0.25rem;
  font-size: 1rem;
}

.homeButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  margin-top: 1rem;
  transition: 0.2s;
  border-radius: 10rem;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: white;
  border: 0.05rem solid orangered;
  color: orangered;
  font-weight: 900;
  cursor: pointer;
  width: 7rem;
  display: flex;
  align-items: center;
}

.homeButton:hover {
  background-color: orangered;
  color: white;
}

.homeButton svg {
  margin-right: 0.25rem;
}

@media (max-width: 991px) {
  .modalContent {
    position: fixed;
    top: 0rem;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(0%);
    max-height: calc(100vh - 0rem);
    width: 100%;
    border-radius: 0;
    z-index: 1000;
  }
}

@media (max-width: 648px) {
  .noNotifications {
    border-radius: 0;
  }
}
