.bodyNoScroll {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: flex-start; /* Align items to the start to keep them at the top */
  justify-content: center;
  padding-top: 2rem; /* Add padding to the top */
  z-index: 1000; /* Ensure it appears above other content */
  overflow-y: hidden;
}

.modalContent {
  position: fixed;
  top: 2rem;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
  max-height: calc(100vh - 0rem);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 36rem;
  background-color: white;
  border-radius: 1rem;
  z-index: 1000;
}

.hidden {
  display: none;
}

.disabledDropdown {
  /* opacity: 0.5; */
  pointer-events: none;
  cursor: not-allowed;
}

.interactions::-webkit-scrollbar {
  display: none;
}

.cardHeader {
  background-color: white;
  padding: 1rem;
  position: sticky;
  display: flex;
  top: 0;
  z-index: 1000;
}

.cardFooter {
  background-color: white;
  padding: 1rem;
  position: fixed;
  bottom: 0rem;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
}

.closeIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.375rem;
  cursor: pointer;

  transition: 0.2s;
  /* margin-right: -0.625rem; */
  /* margin-bottom: -0.25rem; */
}

.closeIcon:hover {
  background-color: whitesmoke;
}

.interactions {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;

  overflow-y: auto;
  padding-bottom: 17.75rem;
}

.label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.roleLabel {
  margin-bottom: 0.5rem;

  font-size: 0.875rem;
}

.linkTextLabel {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.userInput,
.button,
.textArea {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
}

.infoIcon {
  margin-bottom: 1rem;
  margin-right: 0.25rem;
}

.smallPrint {
  color: gray;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.smallPrint svg {
  margin-right: 0.25rem;
}

.smallPrint .charCount {
  margin-top: 0;
}

.smallPrint p {
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.imageContainer {
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.uploadIcon {
  cursor: pointer;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.imageContainer:hover .uploadIcon {
  opacity: 1;
}

.profileButton {
  display: none;
}

.imageContainer:hover img {
  opacity: 0.5;
}

.profileImage {
  margin-bottom: 1rem;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  color: white;
  font-weight: 900;
  font-size: 5rem;
  justify-content: center;
  align-items: center;
  background-color: white; /* Placeholder color, remove if you have an image */
}

.profileImage img {
  width: 100%;
  height: auto;
}

.dateBorder {
  margin-bottom: 1rem;

  border-bottom: 0.05rem solid lightgray;
}
.border {
  margin-bottom: 1rem;
  margin-top: 0.5rem;

  border-bottom: 0.05rem solid lightgray;
}

.textArea {
  background-color: whitesmoke;
  color: black;
  border: 0.05rem solid whitesmoke;
  margin-bottom: 0.5rem;
  display: flex;
  resize: none;
  height: 0;
  padding-bottom: 3.5rem;
}

.textArea::-webkit-scrollbar {
  display: none;
}

.userInput {
  background-color: whitesmoke;
  color: black;

  border: 0.05rem solid whitesmoke;
}

.userInput::placeholder {
  color: grey;
}

.button {
  background-color: orangered;
  border: 0.05rem solid orangered;
  color: white;
  font-weight: 900;
  border-radius: 10rem;
  cursor: pointer;
  width: 100%;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button:hover:enabled {
  filter: brightness(95%);
}

.cardTitle {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 900;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  padding: 0.1rem;
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.charCount {
  font-size: 0.875rem;
  color: gray;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.disabledInput {
  /* opacity: 0.5; */
  cursor: not-allowed;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.customCheckbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1rem;
  height: 1rem;
  margin: 0rem;
  background-color: whitesmoke;
  border: 0.05rem solid whitesmoke;
  border-radius: 10rem;
  outline: none;
  cursor: pointer;
  position: relative;
}

.customCheckbox:checked {
  border-color: white;
}

.customCheckbox:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.125rem;
  height: 1.125rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='orangered' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z' clip-rule='evenodd'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.customCheckbox:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.checkboxContainer label {
  margin-left: 0.25rem;
  font-size: 0.875rem;
}

/* Add these styles for disabled dropdowns */
.dropdownHeader.disabled {
  /* opacity: 0.5; */
  cursor: not-allowed;
}

.date {
  display: flex;
  flex-direction: column;
  gap: -10rem; /* This adds the 0.5rem space between the dropdowns */
}

.disabledLabel {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 991px) {
  .modalContent {
    position: absolute;
    top: 0rem;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(0%);
    max-height: calc(100vh - 0rem);
    width: 100%;
    border: 0rem;
    border-radius: 0;
    z-index: 1000;
  }

  .imageContainer:hover img {
    opacity: 1;
  }

  .cardFooter {
    padding-bottom: 2rem;
  }

  .interactions {
    padding-bottom: 18.75rem;
  }
}
