.bodyNoScroll {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  position: fixed;
  top: 0;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  min-height: calc(100vh - 0rem);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 36rem;
  background-color: white;
  /* border-radius: 1rem 1rem 0 0; */
  z-index: 1000;
}

.cardHeader {
  background-color: white;
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.searchWrapper {
  display: flex;
  align-items: center;
  background-color: whitesmoke;
  border-radius: 10rem;
  padding: 0.5rem;
  flex-grow: 1;
  margin-right: 1rem;
}

.searchIcon {
  margin-right: 0.25rem;
}

.searchInput {
  border: none;
  background: transparent;
  outline: none;
  flex-grow: 1;
  font-size: 1rem;
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.closeIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.375rem;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  transition: 0.2s;
}

.closeIcon:hover {
  background-color: whitesmoke;
}

.emojiPickerContent {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.emojiPickerContent::-webkit-scrollbar {
  display: none;
}

.emojiWrapper {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emojiGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(2rem, 1fr));
  gap: 0.5rem;
  padding: 1rem;
}

.emojiGrid button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}

.sectionTitle {
  font-size: 0.875rem;
  color: gray;
  font-weight: 900;
  padding-left: 1rem;
  /* padding-bottom: 1rem; */
}

.emojiPickerContent {
  overflow-y: auto;
}
/* 
.emojiWrapper button:hover {
  transform: scale(1.5);
} */

@media (max-width: 991px) {
  .modalContent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(0%);
    max-height: calc(100vh - 0rem);
    width: 100%;
    border: 0rem;
    /* border-radius: 1rem 1rem 0 0; */
    z-index: 1000;
    padding-bottom: 1rem;
  }
}
