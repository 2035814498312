.page {
  background-color: white;
  display: flex;
  padding-top: 4rem;
  min-height: 100vh;
}

.content {
  /* background-color: red; */
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.formContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-color: aqua; */
}

.formContent {
  background-color: white;
  width: 24rem;
  margin-left: 2rem;
  border: 0.05rem solid white;
  border-radius: 0.25rem;
  margin-top: 3rem;
  padding: 1rem;
}

.formAbout {
  margin-bottom: 2rem;
}

.primaryAbout {
  font-weight: 900;
  font-size: 1.5rem;
}

.interactions {
  display: flex;
  flex-direction: column;
  /* background-color: bisque; */
}

.highlightedItem {
  background-color: whitesmoke; /* Example hover color */
}

.label {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.button,
.dropdownHeader {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}

.dropdownHeader::placeholder {
  color: grey;
}

.dropdownHeader:hover {
  filter: brightness(95%);
  background-color: whitesmoke;
}

.button {
  background-color: orangered;
  border: 0.05rem solid orangered;
  color: white;
  font-weight: 900;
  cursor: pointer;
  margin-top: 1rem;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button:hover:enabled {
  filter: brightness(95%);
}

.error {
  font-size: 0.75rem;
  color: black;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.errorIcon {
  font-size: 0.75rem;
  margin-right: 0.25rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  padding: 0.1rem;
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.dropdownContainer {
  position: relative;
  cursor: pointer;
}

.dropdownHeader {
  background-color: whitesmoke;
  color: black;
  width: 100%;
  border: 0.05rem solid whitesmoke;
  /* margin-bottom: 1rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chevron {
  font-size: 0rem;
  margin: 0;
  padding: 0;
  margin-top: -0.25rem;
  margin-right: -0.25rem;
  margin-bottom: -0.25rem;
}

.customDropdown {
  position: relative;
  width: 100%;
}

.dropdownHeader.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0.05rem solid lightgray;
  box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);
}

.dropdownListContainer {
  position: absolute;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: whitesmoke;
  border: 0.05rem solid whitesmoke;
  border-top: 0.05rem solid lightgray;
  margin-top: -0.04rem;
  box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);

  width: 100%;
  overflow-y: auto;
  z-index: 999;
  transition: 0.2s;
}

.dropdownList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdownListItem {
  padding: 0.5rem;
  cursor: pointer;
  transition: 0.2s;
}

.dropdownListItem:hover {
  background-color: whitesmoke;
  filter: brightness(95%);
}

.activityMessage {
  margin-top: 1rem;
}

.disabled {
  /* cursor: not-allowed; */
  opacity: 0.5;
}
