.bodyNoScroll {
  width: 100%;
  height: 100%;
  /* position: fixed; */
  overflow: hidden;
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: flex; */
  /* align-items: flex-start; */
  /* justify-content: flex-start; */
  /* z-index: 1000; */
  overflow: hidden;
  transition: 0.2s;
}

.modalContent {
  position: fixed;
  top: 0;
  left: 4rem;
  height: 100%;
  width: calc(100% - 4rem);
  background-color: white;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  overflow: auto;
  transition: 0.2s;
}

.cardFooter {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cardFooter svg {
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
}

.interactions {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow-y: auto;
}

.profileInfo {
  display: flex;
  flex-direction: column;
}

.profileImage {
  object-fit: cover;
  cursor: pointer;

  margin-bottom: 0.75rem;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  overflow: hidden;
}

.userName {
  font-size: 1.25rem;
  font-weight: 900;
  cursor: pointer;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-bottom: 0.5rem;
}

.viewProfile {
  color: orangered;
  font-weight: 900;
  font-size: 1rem;
  text-decoration: none;
}

.viewProfile:hover {
  text-decoration: underline;
}

.button {
  background-color: white;
  border: none;
  display: flex;
  align-items: center;
  border-top: 0.05rem solid lightgray;
  cursor: pointer;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  text-decoration: none;
  color: black;
  transition: 0.2s;
}

.button:hover {
  background-color: whitesmoke;
}

.logOutButton {
  background-color: white;
  border: none;
  display: flex;
  align-items: center;
  border-top: 0.05rem solid lightgray;
  cursor: pointer;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  text-decoration: none;
  color: black;
  transition: 0.2s;
  padding-bottom: 2rem;
}

.logOutButton:hover {
  background-color: whitesmoke;
}
