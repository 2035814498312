.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 1rem;

  background-color: white;
  z-index: 1000;
  border-bottom: 0.05rem solid lightgray;
  justify-content: center;
}

.bottomNav a {
  margin: 0;
  padding: 0;
}

.bottomNav {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  padding-bottom: 2rem;
  background-color: white;
  z-index: 1000;
  border-top: 0.05rem solid lightgray;
}

.icon {
  width: 2rem;
  height: 2rem;
  margin-right: 14.25rem;
  display: flex;
  align-items: center;

  /* margin-bottom: -0.25rem; */
}

.centreIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  margin-right: 14.25rem;
}

.profileIcon {
  object-fit: cover;

  width: 2rem;
  height: 2rem;
  border: none;
  background: none;
  cursor: pointer;
}

.profileIcon {
  object-fit: cover;
  width: 2rem;

  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

@media (max-width: 990px) {
  .nav {
    justify-content: space-between;
  }

  .icon {
    margin-right: 0rem;
  }

  .centreIcons {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .nav .centreIcons {
    display: none;
  }

  .bottomNav {
    display: flex;
    justify-content: space-between;
  }

  .centreIcons {
    justify-content: space-between;
    margin-right: 0rem;
  }

  .bottomNav .icon {
    display: none;
  }

  .bottomNav .icon {
    margin-right: 0rem;
  }

  .bottomNav .centreIcons {
    margin-right: 0rem;
  }
}

.notificationIconWrapper {
  position: relative;
  display: inline-block;
}

.notificationCount {
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  background-color: orangered;
  color: white;
  border-radius: 50%;
  padding: 0.125rem 0.25rem;
  font-size: 0.75rem;
  font-weight: 900;
  min-width: 1.125rem;
  text-align: center;
}
