.page {
  background-color: whitesmoke;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  padding-top: 4rem;
  min-height: 100vh;
}

.content {
  display: flex;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  gap: 2rem;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
}

.cardAbout {
  display: flex;

  margin-right: 1rem;

  flex-direction: column;

  /* gap: 1rem; */
}

.rightCards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 20rem;
  position: sticky;
  top: 6rem; /* Account for the navbar height + some padding */
  height: fit-content;
  align-self: flex-start;
}

.rightCards,
.centreCards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 20rem;
}

.centreCards {
  width: 36rem;
}

.profileHeader {
  display: flex;
  justify-content: space-between;
}

.reactionHeader {
  display: flex;
  font-size: 0.875rem;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.05rem solid lightgray;
}

.reactionHeader span {
  margin-left: 0.25rem;
}

.profileCard {
  cursor: pointer;
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 1rem;
  padding: 1rem;
}

.profileImage {
  margin-bottom: 1rem;
  width: 12rem;
  border-radius: 1rem;
  height: 8rem;
  overflow: hidden;
  display: flex;
  color: white;
  font-weight: 900;
  font-size: 5rem;
  justify-content: center;
  align-items: center;
  background-color: white; /* Placeholder color, remove if you have an image */
}

.profileImage img {
  width: 100%;
  height: auto;
}

.defaultProfileImage {
  width: 12rem;
  height: 8rem;

  background-color: orangered; /* Background color for the circle */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px; /* Adjust font size as needed */
  color: white; /* Color of the initial */
}

.card {
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 1rem;
  padding-bottom: 1rem;

  padding-top: 1rem;
  /* position: absolute;
  top: 6rem;
  bottom: 2rem; */
}

.closeIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  transition: 0.2s;
  cursor: pointer;
}

.closeIcon:hover {
  background-color: whitesmoke;
}

.cardTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.experiencesCard,
.educationCard {
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 0.5rem;
  padding-bottom: 1rem;
}

.experiencesCardTitle,
.educationCardTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 1rem;
  padding: 1rem;
}

.experiencesCardTitle div,
.educationCardTitle div {
  display: flex;
}

.addIcon {
  margin-right: 0.5rem;
  cursor: pointer;
}

.pencilIcon {
  cursor: pointer;
}

.profileTitle {
  font-size: 1.5rem;
  font-weight: 900;
  word-break: break-word;
  margin-bottom: 1rem;
}

.noScroll {
  position: fixed;
  width: 100%;
  overflow: hidden;
}

.connectionType {
  font-size: 0.875rem;
  color: gray;
  margin-left: 0.25rem;
  font-weight: 400;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  position: relative;
}

.noNotifications {
  padding: 1rem;
  color: gray;
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 1rem;
}

.bold {
  font-size: 1rem;
  font-weight: 900;
  color: gray;
}

.text {
  margin-top: 0.25rem;
  font-size: 1rem;
}

.closeIconActive {
  background-color: whitesmoke;
}

.closeIconActive:hover {
  filter: brightness(95%);
}

.homeButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  margin-top: 1rem;
  transition: 0.2s;
  border-radius: 10rem;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: white;
  border: 0.05rem solid orangered;
  color: orangered;
  font-weight: 900;
  cursor: pointer;
  text-decoration: none;
  width: fit-content;
  display: flex;
  align-items: center;
}

.homeButton:hover {
  background-color: orangered;
  color: white;
}

.homeButton svg,
.refreshButton svg {
  margin-right: 0.25rem;
}

.refreshButton {
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: 0.2s;
  border-radius: 10rem;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
  border: 0.05rem solid whitesmoke;
  color: gray;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.refreshButton:hover {
  background-color: whitesmoke;

  filter: brightness(95%);
}

.matchTally {
  display: flex;
  align-items: center;
  color: orangered;
  font-weight: 900;
  padding: 0.5rem;
  background-color: rgba(255, 69, 0, 0.1);
  border: solid 0.05rem transparent;
  height: 100%;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
}

.matchTally svg {
  margin-right: 0.25rem;
}

.noResults {
  color: gray;
}

.spacing {
  margin-right: 0.375rem;
}

.location {
  margin-bottom: 0.25rem;
  color: gray;
  display: flex;
  align-items: center;
}

.svgContainer {
  cursor: pointer;
}

.numbers:hover {
  cursor: pointer;
  text-decoration: underline;
}

.modalBackdrop {
  position: fixed;
  top: 1rem;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalCard {
  background-color: white;
  width: 80%;
  max-width: 600px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.userDetails {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 1rem;
}

.bio {
  word-wrap: break-word; /* Add this line */
  overflow-wrap: break-word; /* Add this line */
}

.dotInfo {
  font-size: 0.75rem;
  color: gray;
  font-weight: 400;
}

.dot {
  font-size: 0.75rem;
  color: gray;
  gap: 0.25rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  font-weight: 400;
}

.data {
  color: orangered;
  font-weight: 900;
  margin-top: 0.25rem;
}

.linkText {
  flex: 1;
  min-width: 0;
}

.link {
  display: inline;
  word-break: break-all;
  overflow-wrap: break-word;
}

.link svg {
  vertical-align: text-bottom;
  margin-left: 0.25rem;
  /* margin-bottom: 0.125rem; */

  display: inline-block;
}

.link a {
  margin-bottom: 0.5rem;
  color: orangered;
  font-weight: 900;
  text-decoration: none;
}

.link a:hover {
  text-decoration: underline;
}

.organisation,
.school {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 900;
  padding-left: 1rem;
  padding-right: 1rem;
}

.role,
.degree,
.subject {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  padding-left: 1rem;
  padding-right: 1rem;
}

.grade {
  font-size: 1rem;
  margin-bottom: 0.5rem;

  padding-left: 1rem;
  padding-right: 1rem;
}

.dates {
  font-size: 1rem;
  color: gray;
  padding-left: 1rem;
  padding-right: 1rem;
}

.experienceContainer:not(:first-child) {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 0.05rem solid lightgray;
}

.educationContainer:not(:first-child) {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 0.05rem solid lightgray;
}

.defaultMessage {
  margin-left: 1rem;
  font-size: 1rem;
}

.disabledPencilIcon {
  cursor: not-allowed;
  opacity: 0.5;
}

.disabled {
  opacity: 0.5;
}

.menu {
  font-weight: 900;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.menuContent {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.educationContent {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: -0.5rem;
}

.experienceContent {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.interests {
  width: 100%;
}

.border {
  border-bottom: 0.05rem solid lightgray;
}

.button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 10rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: orangered;
  border: 0.05rem solid orangered;
  color: white;
  width: fit-content;
  font-weight: 900;
  cursor: pointer;

  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-left: 1rem;
  justify-content: center;
  transition: 0.2s;
}

.viewProfileButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 10rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: white;
  border: 0.05rem solid orangered;
  color: orangered;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.viewProfileButton:hover {
  background-color: orangered;
  color: white;
  filter: brightness(95%);
}

.viewProfileButton svg {
  margin-left: 0.25rem;
}

.mutualConnections {
  color: gray;
  display: flex;
  cursor: pointer;
  align-items: center;
  max-width: 100%;
  margin-bottom: 1rem;
  overflow: hidden; /* Ensures containment */
}

.mutualConnectionsImages {
  display: flex;
  align-items: center;
  min-width: 0; /* Allows container to shrink */
}

.mutualConnectionImage {
  width: 2rem;
  height: 2rem;

  object-fit: cover;
  margin-left: -0.75rem; /* Overlap effect */
  flex-shrink: 0; /* Prevents image shrinking */
}

.mutualConnectionImage:first-child {
  margin-left: 0;
}

.mutualConnectionCount {
  margin-left: 0.5rem;
  font-weight: 900;
  color: gray;
  white-space: nowrap; /* Keeps +N on one line */
  flex-shrink: 0; /* Prevents number shrinking */
}

.secondImage {
  left: 1rem;
  z-index: 1;
}

.singleImage {
  margin-right: 0.25rem;
}

.multipleImages {
  margin-right: 1.25rem;
}

.boldName {
  font-weight: 900;
  color: gray;
}

.button svg {
  margin-right: 0.25rem;
}

.mutualConnectionText {
  margin-top: 0.25rem;
  margin-left: 0.25rem;
  cursor: pointer;
  display: inline-block;
  max-width: calc(100% - 2.5rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mutualConnectionText:hover {
  text-decoration: underline;
  text-decoration-color: orangered;
  color: orangered;
}

.mutualConnections:hover .boldName {
  text-decoration: underline;
  text-decoration-color: orangered;
  color: orangered;
}

.mutualConnections:hover .mutualConnectionText {
  text-decoration: underline;
  text-decoration-color: orangered;
  color: orangered;
}

.searchButton {
  display: flex;
  align-items: center;
}

.inputWrapper {
  position: relative;
  width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.searching {
  margin-left: 0.25rem;
}

.searchIcon {
  position: absolute;
  left: 0.5rem; /* Adjust as needed */
  width: 1rem;
  height: 1rem;
}

.userInput {
  padding: 0.5rem;
  padding-left: 1.75rem;
  font-size: 1rem;
  margin-right: 1rem;
  width: 100%;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
  border: 0.05rem solid whitesmoke;
  color: black;
  /* width: 100%; */
}

.buttons {
  display: flex;
  align-items: center;
}
.userInput::placeholder {
  color: grey;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button:hover:enabled {
  filter: brightness(95%);
}

.infoIcon {
  margin-right: 0.25rem;
}

.skillsInput {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  border: 0.05rem solid whitesmoke;
  color: black;
  background-color: whitesmoke;
  font-family: "Roboto", sans-serif;
  max-height: 4.25rem;
  min-height: 4.25rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  padding: 0.1rem;
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.pageLoader {
  color: gray;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.degreeLabel {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: gray;
}

.filterLabel {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: gray;
}

.countryLabel {
  padding-bottom: 0.5rem;
}

.organisationLabel {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  font-size: 0.875rem;
  color: gray;
}

.skillsLabel {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  font-size: 0.875rem;
  color: gray;
}

.label {
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.filters svg {
  margin-left: 0.25rem;
}

.filters {
  display: flex;
  padding-left: 1rem;
  width: 100%;
  flex-direction: column;
}

.countryLabel {
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.cityLabel {
  margin-bottom: 0.5rem;
  /* margin-top: 0.25rem; */
  font-size: 0.75rem;
  font-weight: 500;
}

.skillsInput::-webkit-scrollbar {
  display: none;
}

.smallPrint {
  color: gray;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  margin-left: -0.25rem;
}

.smallerPrint {
  color: gray;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  margin-left: -0.25rem;
}

.smallerPrint p {
  font-size: 0.875rem;
  margin-bottom: 0;
  margin-left: 0.25rem;
}

.section {
  border-top: 0.05rem solid lightgray;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bottom {
  border-top: 0.05rem solid lightgray;
  padding-top: 1rem;
}

.charCount {
  font-size: 0.875rem;
  color: gray;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.roleCharCount {
  font-size: 0.875rem;
  color: gray;
  display: flex;
  justify-content: flex-end;
}

.smallPrint .charCount {
  margin-top: 0;
}

.smallPrint p {
  font-size: 0.875rem;
  margin-bottom: 0;
}

.interestsContent {
  margin-left: 1rem;
  margin-right: 1rem;
}

.chevron {
  font-size: 0rem;
  margin: 0;
  padding: 0;
  margin-top: -0.25rem;
  margin-right: -0.25rem;
  margin-bottom: -0.25rem;
}

@media (max-width: 990px) {
  .content {
    display: block;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    gap: 0rem;
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
  }

  .rightCards,
  .centreCards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* margin-bottom: 1rem; */
    width: 100%;
  }

  .rightCards {
    padding-top: 1rem;
  }

  .centreCards {
    width: 100%;
  }

  .profileHeader {
    display: flex;
    justify-content: space-between;
  }

  .profileCard {
    background-color: white;
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0.05rem solid lightgray;
    border-right: 0.5srem solid lightgray; */
    padding: 1rem;
  }

  .profileImage {
    margin-bottom: 1rem;
    width: 12rem; /* Adjust size as needed */
    height: 8rem; /* Adjust size as needed */

    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white; /* Placeholder color, remove if you have an image */
  }

  .profileImage img {
    width: 100%;
    height: auto;
  }

  .card {
    background-color: white;
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0.05rem solid lightgray;
    border-right: 0.05em solid lightgray; */
    /* margin-bottom: 1rem; */
    /* margin-top: 1rem; */
  }
}

@media (max-width: 648px) {
  .noNotifications {
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray; */
    border-radius: 0rem;
  }
  .content {
    display: block;
    padding-top: 1rem;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 5.8rem;
    /* margin-bottom: ; */
    gap: 0rem;
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
  }

  .rightCards,
  .centreCards {
    display: flex;
    flex-direction: column;
    /* justify-self: center; */
    gap: 1rem;
    width: 100%;
  }

  .centreCards {
    width: 100%;
  }

  .profileHeader {
    display: flex;
    justify-content: space-between;
  }

  .profileCard {
    background-color: white;
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray; */
    border-radius: 0rem;
    padding: 1rem;
  }

  .experiencesCard,
  .educationCard {
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray; */
    border-radius: 0rem;
  }

  .profileImage {
    margin-bottom: 1rem;
    width: 12rem; /* Adjust size as needed */
    height: 8rem; /* Adjust size as needed */

    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white; /* Placeholder color, remove if you have an image */
  }

  .profileImage img {
    width: 100%;
    height: auto;
  }

  .card {
    background-color: white;
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray; */
    border-radius: 0rem;
  }

  .interestsMenu {
    font-weight: 900;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .noResults {
    margin-left: 1rem;
  }
}
