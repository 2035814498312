.bodyNoScroll {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.closeIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.375rem;
  transition: 0.2s;
  cursor: pointer;

  /* margin-right: -0.625rem; */
  /* margin-bottom: -0.25rem; */
}

.closeIcon:hover {
  background-color: whitesmoke;
}

.modalBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 2rem;
  z-index: 1000;
  overflow-y: hidden;
}

.modalContent {
  position: fixed;
  top: 0rem;
  left: 50%;
  bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  transform: translateX(-50%);
  max-height: calc(100vh - 0rem);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 36rem;
  background-color: white;
  /* border-radius: 1rem 1rem 0 0; */
  z-index: 1000;
}

.repostsList::-webkit-scrollbar {
  display: none;
}

.cardHeader {
  background-color: white;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-right: 2rem;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 900;
}

.searchContainer {
  margin: 1rem;
}

.userInput {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
  border: 0.05rem solid lightgray;
  color: black;
  width: 100%;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.loadingIcon {
  font-size: 1rem;
  color: gray;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.userInput::placeholder {
  color: grey;
}

.repostsList {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 4.25rem;
  padding-bottom: 1rem;
}

.repostItem {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: none;
  border-radius: 0;
  border-top: 0.05rem solid lightgray;
}

.repostItem:first-child {
  border-top: none;
}

.userContainer {
  margin-right: 0.5rem;
}

.profileImage {
  cursor: pointer;
  width: 4rem;

  height: 4rem;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  flex-shrink: 0;
}

.nameWrapper {
  display: inline-flex;
  max-width: 100%;
  overflow: hidden;
}

.repostTime {
  font-size: 0.875rem;
  color: gray;
}

.userInfo {
  flex-grow: 1;
  min-width: 0;

  overflow: hidden;
}

.nameText {
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 0.2s;
}

.name {
  font-size: 1rem;
  color: black;
  font-weight: 900;
  text-decoration: none;
  width: 100%;
  overflow: hidden;
}

.nameText:hover {
  cursor: pointer;
  text-decoration: underline;
}

.connectionType {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 0.25rem;
  font-size: 0.875rem;
  color: gray;
  font-weight: 400;
  white-space: nowrap;
}

.bio {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: gray;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-decoration: none;
}

.noBio {
  margin-top: 0rem;
}

.default {
  font-size: 1rem;
  margin-left: 1rem;
  color: gray;
  text-decoration: none;
}

.noNotifications {
  padding: 1rem;
  color: gray;
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 1rem;
}

.bold {
  font-size: 1rem;
  font-weight: 900;
  color: gray;
}

.text {
  margin-top: 0.25rem;
  font-size: 1rem;
}

.homeButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  margin-top: 1rem;
  transition: 0.2s;
  border-radius: 10rem;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: white;
  border: 0.05rem solid orangered;
  color: orangered;
  font-weight: 900;
  cursor: pointer;
  width: 7rem;
  display: flex;
  align-items: center;
}

.homeButton:hover {
  background-color: orangered;
  color: white;
}

.homeButton svg {
  margin-right: 0.25rem;
}

@media (max-width: 991px) {
  .modalContent {
    position: fixed;
    top: 0rem;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(0%);
    max-height: calc(100vh - 0rem);
    width: 100%;
    /* border-radius: 1rem 1rem 0 0; */
    z-index: 1000;
  }
}

@media (max-width: 648px) {
  .noNotifications {
    border-radius: 0;
  }
}
