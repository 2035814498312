.bodyNoScroll {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.closeIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.375rem;
  cursor: pointer;

  transition: 0.2s;
  /* margin-right: -0.625rem; */
  /* margin-bottom: -0.25rem; */
}

.closeIcon:hover {
  background-color: whitesmoke;
}

.modalBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
  height: 100%;
}

.postContentWrapper {
  width: 100%;
  overflow: hidden;
}

.modalContent {
  position: fixed;
  top: 2rem;
  left: 50%;
  bottom: 2rem;
  /* padding-left: 1rem; */
  /* padding-right: 1rem; */
  transform: translateX(-50%);
  max-height: calc(100vh - 0rem);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 36rem;
  background-color: white;
  border-radius: 1rem;
  z-index: 1000;
}

.scrollableContent {
  flex-grow: 1;
  overflow-y: auto;

  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;

  padding-bottom: 8rem; /* Adjust based on your footer height */
}

.scrollableContent::-webkit-scrollbar {
  display: none;
}

.icon {
  margin-left: 0.75rem;
}

.cardHeader {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.fileIcon {
  margin-left: -0.875rem;
  padding: 0;
}
.cardFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  /* border-top: 0.1rem solid black; */
  right: 0;
  background-color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 11; /* Higher than fileUploadIcons to ensure it's always on top */
}

.interactions {
  flex-grow: 1;
  padding-left: 1rem;
  padding-right: 1rem;

  overflow-y: auto;
}

.label {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.fileIcons {
  display: flex;
  align-items: center;
}
.fileUploadIcons {
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  background-color: white;
  z-index: 10;
}

.fileText {
  display: flex;
  margin-left: auto;
}

.textAreaContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

.textArea::-webkit-scrollbar {
  display: none;
}

.textArea {
  width: 100%;
  min-height: 100%;
  font-size: 1rem;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  flex-grow: 1;
  background-color: white;
  color: black;
  border: none;
  margin-bottom: 2rem;
  display: flex;
  resize: none;
}

.videoIcon {
  margin-left: 0.875rem;
  margin-right: 0.875rem;
  cursor: pointer;
}

.filePreviewContainer {
  margin-top: 1rem;
  position: relative;
}

.videoPreviewContainer {
  position: relative;
  width: 100%;

  overflow: hidden;
}

.videoThumbnail {
  width: 100%;
}

.imageCarousel {
  position: relative;
  width: 100%;
  transition: 0.2s;
  overflow: hidden;
  cursor: pointer;
}

.carouselControls {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 100rem;
  padding: 0.25rem;
}

.imageCarousel {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.postContentImage {
  pointer-events: none;
  cursor: pointer;
}

.carouselButton {
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  padding: 0 0.5rem;
}

.carouselButton:hover {
  opacity: 0.5;
}

.imageCarousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.pdfCloseIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.375rem;
  cursor: pointer;

  transition: 0.2s;
  /* margin-right: -0.625rem; */
  /* margin-bottom: -0.25rem; */
}

.imageCloseIcon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  padding: 0.375rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.navigationButtons {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  display: flex;
  gap: 0.5rem;
  z-index: 2;
}

.navButton {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s;
}

.navButton svg {
  margin: 0 !important;
  padding: 0 !important;
}

.navButton:hover {
  background-color: rgba(0, 0, 0, 1);
}

.imageCounter {
  display: none;
}

.imageCounterInner {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

/* Update existing classes */
.imageWrapper {
  position: relative;
  display: none;
  transition: 0.2s;
}

.activeImage {
  display: block;
}

.postContentImage {
  width: 100%;
  height: auto;
  border-radius: 1rem;
  display: block;
}

.dotNavigation {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.dot {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0 0.25rem;
  cursor: pointer;
  transition: 0.2s;
}

.activeDot {
  background-color: white;
  width: 0.5rem;
  height: 0.5rem;
}

.videoPlayer {
  width: 100%;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.playButton span {
  color: white;
  font-size: 36px;
}

.imagePreview,
.videoPreview {
  width: 100%;
  height: auto;
  border-radius: 1rem;
  display: block;
  object-fit: contain;
  max-width: 100%;

  margin-bottom: 1rem;
}

.removeFileIcon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 0.25rem;
}

.fileAttachmentText {
  margin-left: 0.5rem;
  font-size: 1rem;
}

.confirmDialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.filePreview {
  width: 100%;
  object-fit: cover;

  position: relative;
}

.uploadingPreview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.imageCloseIcon:hover {
  background-color: rgba(0, 0, 0, 1);
}

.pdfPreview {
  display: block;
  text-decoration: none;
  width: 100%;

  padding-bottom: 1rem;
  background-color: white;
}

.pdfBox {
  width: 100%;
  border-radius: 0.5rem;
  background-color: rgba(255, 69, 0, 0.1);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fileContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.fileAbout {
  display: flex;
  flex-direction: column;
}

.fileName {
  color: orangered;
  font-size: 1.5rem;
  font-weight: 900;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.05rem solid orangered;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.open {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: orangered;
  text-decoration: none;
  font-weight: 900;
  margin-top: auto;
}

.open svg {
  margin-right: 0.25rem;
}

.open:hover {
  text-decoration: underline;
}

.pdfPreviewNoInteractions {
  margin-bottom: 1rem;
}

.loadingIcon {
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.confirmDialog {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}
z .confirmDialog p {
  margin-bottom: 20px;
}

.confirmDialogButtons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.deleteButton,
.cancelButton {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.deleteButton {
  background-color: #ff4d4f;
  color: white;
}

.cancelButton {
  background-color: #d9d9d9;
  color: black;
}

.fileAttachmentText.uploading {
  color: gray;
  font-weight: 400;
}

.fileAttachmentText.attached {
  color: orangered;
  font-weight: 900;
}

.removeFileIcon:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.videoPreviewUnavailable {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: whitesmoke;
}

.videoPreviewUnavailable span {
  color: gray;
  font-weight: 900;
}

.videoFileName {
  font-size: 1rem;
  color: gray;
}

.button {
  background-color: orangered;
  border: 0.05rem solid orangered;
  color: white;
  font-weight: 900;
  cursor: pointer;
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 10rem;

  box-sizing: border-box;
  outline: none;

  font-family: "Roboto", sans-serif;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button:hover:enabled {
  filter: brightness(95%);
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 900;
}

.cardTitle svg {
  cursor: pointer;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fileIcon,
.imageIcon {
  cursor: pointer;
}

.charCount {
  padding-bottom: 0.25rem;
  font-size: 0.875rem;

  color: gray;
}

@media (max-width: 991px) {
  /* .modalBackdrop {
    align-items: stretch;
    padding: 0;
  } */
  .modalContent {
    top: 0rem;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(0%);
    max-height: calc(100vh - 0rem);
    width: 100%;
    border-radius: 0;
    z-index: 1000;
  }

  .scrollableContent {
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: 8rem; /* Adjust based on your footer height */
  }

  .cardFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 2rem;
  }

  .fileUploadIcons {
    position: fixed;
    bottom: 3rem; /* Adjust based on your footer height */
    left: 0;
    right: 0;
  }
}

.textAreaWrapper {
  position: relative;
}

.textArea {
  width: 100%;
  min-height: 100%;
  font-size: 1rem;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  flex-grow: 1;
  background-color: white;
  color: transparent; /* Make text transparent */
  caret-color: black; /* Keep cursor visible */
  border: none;
  margin-bottom: 2rem;
  display: flex;
  resize: none;
}

.textArea::-webkit-scrollbar {
  display: none;
}

.textOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  white-space: pre-wrap;
  pointer-events: none;
  word-wrap: break-word;

  color: black;
  z-index: 2;
}

.textOverlay .mention {
  font-weight: 900 !important;
}
