.bodyNoScroll {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: flex-start; /* Align items to the start to keep them at the top */
  justify-content: center;
  padding-top: 2rem; /* Add padding to the top */
  z-index: 1000; /* Ensure it appears above other content */
  overflow-y: hidden;
}

.modalContent {
  position: fixed;
  top: 2rem;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
  max-height: calc(100vh - 0rem);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 36rem;
  background-color: white;
  border-radius: 1rem;
  z-index: 1000;
}

.cardHeader {
  background-color: white;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.cardFooter {
  background-color: white;
  border-top: 0.05rem solid lightgray;
  padding: 1rem;
  position: fixed;
  bottom: 0rem;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
}

.label {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.linkTextLabel {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.userInput,
.button,
.textArea {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
}

.smallPrint {
  /* text-align: center; */
  color: gray;
  margin-top: -0.25rem;
  /* margin-left: 1rem;
  margin-right: 1rem; */
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.smallPrint .charCount {
  margin-top: 0;
}

.smallPrint p {
  font-size: 0.75rem;
  margin-bottom: 0;
  margin-top: -0.5rem;
}

.imageContainer {
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.uploadIcon {
  cursor: pointer;
  position: absolute;
  /* top: 45%; */
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.imageContainer:hover .uploadIcon {
  opacity: 1;
}

.profileButton {
  display: none;
}

.imageContainer:hover img {
  opacity: 0.5;
}

.profileImage {
  margin-bottom: 1rem;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  color: white;
  font-weight: 900;
  font-size: 5rem;
  justify-content: center;
  align-items: center;
  background-color: white; /* Placeholder color, remove if you have an image */
}

.profileImage img {
  width: 100%;
  height: auto;
}

.textArea {
  background-color: whitesmoke;
  color: black;
  border: 0.05rem solid lightgray;
  margin-bottom: 0.5rem;
  display: flex;
  resize: none;
  height: 0;
  padding-bottom: 3.5rem;
}

.userInput {
  background-color: whitesmoke;
  color: black;
  border: 0.05rem solid lightgray;
  margin-bottom: 0.5rem;
}

.noNotifications {
  color: gray;
  background-color: white;
  /* border: 0.05rem solid lightgray; */
  border-radius: 1rem;
}

.bold {
  font-size: 1rem;
  font-weight: 900;
  color: gray;
}

.text {
  margin-top: 0.25rem;
  font-size: 1rem;
}

.homeButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  text-decoration: none;
  margin-top: 1rem;
  transition: 0.2s;
  border-radius: 10rem;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: white;
  border: 0.05rem solid orangered;
  color: orangered;
  font-weight: 900;
  cursor: pointer;
  width: 7rem;
  display: flex;
  align-items: center;
}

.homeButton:hover {
  background-color: orangered;
  color: white;
}

.homeButton svg {
  margin-right: 0.25rem;
}

.userInput::placeholder {
  color: grey;
}

.button {
  background-color: orangered;
  border: 0.05rem solid orangered;
  color: white;
  font-weight: 900;
  border-radius: 10rem;
  cursor: pointer;
  width: 100%;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button:hover:enabled {
  filter: brightness(95%);
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 900;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIndicator {
  display: flex;
  justify-content: center;
}

.loadingIcon {
  animation: spin 1s linear infinite;
  font-size: 1rem; /* Adjust size as needed */
  color: gray; /* Adjust color as needed */
}

.loadingIcon {
  padding: 0.1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.charCount {
  font-size: 0.75rem;
  color: gray;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.disabledInput {
  opacity: 0.5;
  cursor: not-allowed;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.customCheckbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1rem;
  height: 1rem;
  margin-left: 0rem;
  border: 0.05rem solid lightgray;
  border-radius: 0.25rem;
  outline: none;
  cursor: pointer;
  position: relative;
}

.customCheckbox:checked {
  background-color: orangered;
  border-color: orangered;
}

.customCheckbox:checked::before {
  content: "\2713";
  font-size: 0.75rem;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.customCheckbox:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.checkboxContainer label {
  margin-left: 0.25rem;
  font-size: 0.75rem;
}

/* Add these styles for disabled dropdowns */
.dropdownHeader.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.date {
  display: flex;
  gap: 1rem;
}

.date > div {
  flex: 1;
}

.disabledLabel {
  opacity: 0.5;
  cursor: not-allowed;
}

.searchContainer {
  margin-top: 2rem;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.searchIcon {
  position: absolute;
  left: 0.5rem; /* Adjust as needed */
  width: 1rem;
  height: 1rem;
  margin-top: -1.5rem;
}

.userInput {
  padding: 0.5rem;
  margin-top: -1rem;
  padding-left: 1.75rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
  border: 0.05rem solid whitesmoke;
  color: black;
  width: 100%;
}

.userInput::placeholder {
  color: grey;
}

.experiencesCardAbout {
  display: flex;
  flex-direction: column;
  padding-top: 8.125rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4rem;
  overflow-y: auto;
}

.experiencesCardAbout::-webkit-scrollbar {
  display: none;
}

.noExperienceMessage {
  font-size: 1rem;
  padding: 2rem 1rem 1rem 1rem;
}

.organisation {
  font-size: 1rem;
  font-weight: 900;
  margin-bottom: 0.25rem;
  word-wrap: break-word; /* Add this line */
  overflow-wrap: break-word; /* Add this line */
}

.role {
  font-size: 1rem;
  margin-bottom: 0.25rem;
  word-wrap: break-word; /* Add this line */
  overflow-wrap: break-word; /* Add this line */
}

.description {
  font-size: 1rem;
  margin-bottom: 0;
  margin-top: 1rem;
  word-wrap: break-word; /* Add this line */
  overflow-wrap: break-word; /* Add this line */
}

.dates {
  font-size: 1rem;
  color: gray;
  margin: 0;

  /* padding-left: 1rem; */
  /* padding-right: 1rem; */
}

.closeIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  transition: 0.2s;
  cursor: pointer;

  /* margin-right: -0.625rem; */
  /* margin-bottom: -0.25rem; */
}

.closeIcon:hover {
  background-color: whitesmoke;
}

.modalContent::-webkit-scrollbar {
  display: none;
}

.experienceContainer::-webkit-scrollbar {
  display: none;
}

.experienceContainer:first-child {
  border-top: none;
  padding-top: 0;
}

.experienceContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 3rem; */
}

.experienceInfo {
  flex-grow: 1;
  min-width: 0;
  margin-right: 0.5rem;
}

.experienceContainer {
  padding: 1rem 0;
  border-top: 0.05rem solid lightgray;
}

.experienceHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.iconContainer {
  position: relative;
  /* justify-content: center; */
  /* width: 30px; */
  /* height: 30px; */
}

.dropdown {
  position: absolute;
  top: 3rem;
  right: 0;
  box-shadow: 1px 2px 3px hsla(0, 0%, 0%, 0.3);

  background-color: whitesmoke;
  border-radius: 1rem 0 1rem 1rem;
  /* border: 0.05rem solid lightgray; */
  display: flex;
  z-index: 1000;
  flex-direction: column;
  /* box-shadow: 0 0rem 0.25rem rgba(0, 0, 0, 0.1); */
}

.dropdownItem svg {
  margin-right: 0.25rem;
}

.dropdownItemFirst {
  border-radius: 1rem 0 0 0;
}

.dropdownItemLast {
  border-radius: 0 0 1rem 1rem;
}

.dropdownItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  color: gray;
  font-size: 1rem;
  font-family: "Roboto";

  border: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s;
  background-color: whitesmoke;
}

.dropdownItem:hover {
  /* background-color: whitesmoke; */
  filter: brightness(95%);
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
}

.editIcon,
.optionsIcon {
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
  transition: 0.2s;
  cursor: pointer;
}

.editIcon:hover,
.optionsIcon:hover,
.optionsIconActive {
  background-color: whitesmoke;
}

@media (max-width: 991px) {
  .modalContent {
    position: absolute;
    top: 0rem;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(0%);
    max-height: calc(100vh - 0rem);
    width: 100%;
    border-radius: 0;
    z-index: 1000;
  }

  .imageContainer:hover img {
    opacity: 1;
  }
}

@media (max-width: 648px) {
  .noNotifications {
    /* border-top: 0.05rem solid lightgray;
    border-bottom: 0.05rem solid lightgray;
    border-left: 0rem solid lightgray;
    border-right: 0rem solid lightgray; */
    border-radius: 0rem;
  }
}
